import React, { useContext, useEffect, useState } from 'react'
import 'chartjs-adapter-date-fns'
import { FcPortraitMode, FcNightPortrait } from 'react-icons/fc'
import { apiGet } from 'utils/apiFetch'
import pathObj from 'utils/pathObj'
import transactionSvg from 'assets/icons/transaction.svg'
import CountUp from 'react-countup'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { Link, useNavigate } from 'react-router-dom'

function Home () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  let { logoutUser, updatePageName, setActiveUser } = useContext(AuthContext)

  const [dashboardDetails, setDashboardDetails] = useState({})
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState('')
  const [isReset, setIsReset] = useState(false)
  const handleDateChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)
    setIsReset(false)
  }

  const formatDate = date => (date ? dayjs(date).format('YYYY-MM-DD') : null)

  const buildPayload = () => ({
    startDate: formatDate(startDate),
    endDate: formatDate(endDate)
  })

  const handleApiCall = async () => {
    const payload = buildPayload()
    const path = pathObj.getDashboardDetails

    try {
      const result = await apiGet(path, payload)
      setDashboardDetails({ ...dashboardDetails, ...result.data.results })
    } catch (error) {
      handleApiError(error)
    }
  }

  const handleApiError = error => {
    console.error('error:', error)
    if (error.response?.status === 401 || error.response?.status === 409) {
      logoutUser()
    }
  }

  const getDashboardDetails = async () => {
    try {
      await handleApiCall()
    } catch (error) {
      console.error('Unhandled error:', error)
    }
  }

  useEffect(() => {
    getDashboardDetails()
  }, [startDate, endDate])

  const handleReset = () => {
    setEndDate('')
    setStartDate('')
    setIsReset(true)
  }

  useEffect(() => {
    updatePageName(t('NAV_DASHBOARD'))
  }, [])
  return (
    <>
      <div className='dark:bg-gray-800 sm:flex items-center text-center sm:text-left px-3 md:px-4 xl:px-7 lg:px-5  md:py-5 py-4 md:py-8 border'>
        <ODateRangePicker
          handleDateChange={handleDateChange}
          isReset={isReset}
          setIsReset={setIsReset}
        />
        <button
          type='button'
          onClick={handleReset}
          className='bg-LightBlue text-sm px-8 mb-3 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
        >
          {t('O_RESET')}
        </button>
      </div>

      <div className='py-4 px-4 md:px-8 dark:bg-LightBlue'>
        <div className='sale_report grid  3xl:grid-cols-4 gap-4 2xl:grid-cols-3 sm:grid-cols-2 mb-7 '>
          <Link to='/user-manager'>
            <div className='flex items-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.totalUsers ? (
                  <CountUp duration={0.6} end={dashboardDetails?.totalUsers} />
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block dark:text-white'>
                  {t('NUMBER_OF_USERS_REGISTERED')}
                </span>
              </h3>
              <span className='text-4xl ml-auto sm:mr-0  mt-2 sm:mt-0'>
                <FcPortraitMode />
              </span>
            </div>
          </Link>
          <div
            className='cursor-pointer'
            onClick={() => {
              navigate('/user-manager')
              setActiveUser('active')
            }}
          >
            <div className='flex items-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.activeUsers ? (
                  <CountUp duration={0.6} end={dashboardDetails?.activeUsers} />
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block  dark:text-white'>
                  {t('NUMBER_OF_ACTIVE_USERS')}
                </span>
              </h3>
              <span className='text-4xl ml-auto sm:mr-0 mt-2 sm:mt-0'>
                <FcPortraitMode />
              </span>
            </div>
          </div>
          <div
            className='cursor-pointer'
            onClick={() => {
              navigate('/user-manager')
              setActiveUser('inactive')
            }}
          >
            <div className='flex items-center sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.inactiveUsers ? (
                  <CountUp
                    duration={0.6}
                    end={dashboardDetails?.inactiveUsers}
                  />
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block dark:text-white'>
                  {t('NUMBER_OF_NON_ACTIVE_USERS')}
                </span>
              </h3>
              <span className='text-4xl ml-auto sm:mr-0 mt-2 sm:mt-0'>
                <FcPortraitMode />
              </span>
            </div>
          </div>
          <Link to='/transaction-manager'>
            <div className='flex items-center  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.transactions ? (
                  <CountUp
                    duration={0.6}
                    end={dashboardDetails?.transactions}
                  />
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('NUMBER_OF_TOTAL_TRANSACTIONS')}
                </span>
              </h3>
              <span className='text-4xl ml-auto sm:mr-0 mt-2 sm:mt-0'>
                <FcNightPortrait />
              </span>
            </div>
          </Link>
          <Link to='/transaction-manager'>
            <div className='flex items-center  sm:text-left px-3 md:px-4 xl:px-6 lg:px-5 rounded-lg md:py-5 py-4 md:py-8 border'>
              <h3 className='xl:flex block items-center mb-0 text-slate-900 font-bold md:text-2xl sm:text-lg text-lg'>
                {dashboardDetails?.earningThroughTransactionFee ? (
                  <CountUp
                    duration={0.6}
                    end={dashboardDetails?.earningThroughTransactionFee}
                  />
                ) : (
                  0
                )}
                <span className='lg:ml-4 text-base text-neutral-400 font-normal block lg:inline-block'>
                  {t('TOTAL_EARNING_THROUGH_TRANSACTION_FEE')}
                </span>
              </h3>
              <span className='ml-auto sm:mr-0  mt-2 sm:mt-0'>
                <img src={transactionSvg} className='h-8 w-8' alt='' />
              </span>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Home
