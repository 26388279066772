import { validationRules } from './constants'

const formValidation = {
  firstName: {
    required: 'Please enter full name.',
    pattern: {
      value: validationRules.characters,
      message: validationRules.charactersMessage
    },
    minLength: {
      value: 2,
      message: 'Minimum length must be 2.'
    }
  },
  firstNames: {
    required: 'Please enter first name.',
    pattern: {
      value: validationRules.characters,
      message: validationRules.charactersMessage
    },
    minLength: {
      value: 2,
      message: 'Minimum length must be 2.'
    }
  },
  redirectLink: {
    pattern: {
      value: /(https?:\/\/[^\s]+)/gi,
      message: 'Invalid re-direction link.'
    },
    maxLength: {
      value: 500,
      message: 'Re-direction link should not exceed 500 characters.'
    }
  },
  description: {
    required: 'Please enter notification description.',
    minLength: {
      value: 2,
      message: 'Notification description should contain at least 2 characters.'
    },
    maxLength: {
      value: 350,
      message: 'Notification description should not exceed 350 characters.'
    }
  },
  lastName: {
    required: 'Please enter last name.',
    pattern: {
      value: validationRules.characters,
      message: validationRules.charactersMessage
    },
    minLength: {
      value: 2,
      message: 'Minimum length must be 2.'
    }
  },
  business_name: {
    required: 'Please enter business name.',
    pattern: {
      value: /^[^\s].*/,
      message: 'Cannot start with a space.'
    },
    minLength: {
      value: 2,
      message: 'Minimum length must be 2.'
    }
  },
  email: {
    required: 'Please enter email ID.',
    pattern: {
      value: validationRules.email,
      message: 'Please enter valid email ID as: example@domain.com.'
    }
  },
  emailAddress: {
    required: 'Please enter email address.',
    pattern: {
      value: validationRules.email,
      message: 'Please enter valid email address as: example@domain.com.'
    }
  },
  mobile: {
    required: 'Please enter mobile number.',
    minLength: {
      value: 9,
      message: 'Minimum length should be 9 digits.'
    },
    min: {
      value: 0,
      message: 'Minimum value must is 0.'
    },
    maxLength: {
      value: 9,
      message: 'Maximum length should be 9 digits.'
    }
  },
  refCode: {},
  banner_name: {
    required: 'Please enter banner name.',
    pattern: {
      value: /^[^\s].*/,
      message: 'Cannot start with a space.'
    },
    minLength: {
      value: 2,
      message: 'Minimum length must be 2.'
    }
  },
  name: {
    required: 'Please enter name.',
    minLength: {
      value: 2,
      message: 'Minimum length must be 2.'
    },
    pattern: {
      value: /^[^\s].*/,
      message: 'Cannot start with a space.'
    }
  },
  username: {
    required: 'Please enter username.',
    pattern: {
      value: /^[^\s].*/,
      message: 'Cannot start with a space.'
    },
    minLength: {
      value: 2,
      message: 'Minimum length must be 2.'
    }
  },
  business_address: {
    required: 'Please enter business address.',
    pattern: {
      value: /^[^\s].*/,
      message: 'Cannot start with a space.'
    },
    maxLength: {
      value: 200,
      message: 'Maximum character can be 200.'
    },
    minLength: {
      value: 10,
      message: 'Minimum length must be 10.'
    }
  },
  title: {
    required: 'Please enter title.',
    pattern: {
      value: /^[^\s].*/,
      message: 'Cannot start with a space.'
    },
    minLength: {
      value: 2,
      message: 'Minimum length must be 2.'
    },
    maxLength: {
      value: 200,
      message: 'Maximum length must be 200.'
    }
  },
  subject: {
    required: 'Please enter subject.',
    pattern: {
      value: /^[^\s].*/,
      message: 'Cannot start with a space.'
    },
    minLength: {
      value: 2,
      message: 'Minimum length must be 2.'
    }
  },
  code: {
    required: 'Please enter code.',

    maxLength: 40,
    minLength: {
      value: 3,
      message: 'Minimum length must be 3.'
    }
  },
  maxSalary: {
    required: 'Please enter maximum salary.',
    minLength: {
      value: 1,
      message: 'Minimum length should be 1 digits.'
    },
    maxLength: {
      value: 6,
      message: 'Maximum length should be 6 digits.'
    },
    pattern: {
      value: validationRules.numbers,
      message: 'Invalid maximum salary.'
    },
    min: {
      value: 0,
      message: "Value can't be less than 0."
    }
  },
  maxLoanAmount: {
    required: 'Please enter maximum loan amount.',
    minLength: {
      value: 1,
      message: 'Minimum length should be 1 digits.'
    },
    maxLength: {
      value: 6,
      message: 'Maximum length should be 6 digits.'
    },
    pattern: {
      value: /^[^\s].*/,
      message: 'Cannot start with a space.'
    },
    min: {
      value: 0,
      message: "Value can't be less than 0."
    }
  },
  minimum_withdrawal_request: {
    required: {
      value: true,
      message: 'Please enter minimum withdrawal request.'
    },
    pattern: {
      value: /^\d+$/,
      message: 'Decimals not allowed.'
    },
    maxLength: {
      value: 10,
      message: 'Maximum limit is 10 characters.'
    },
    min: {
      value: 1,
      message: 'Minimum value must is 1.'
    }
  }
}

export default formValidation
