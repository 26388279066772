import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Table from './Table'
import Pagination from '../Pagination'
import AuthContext from 'context/AuthContext'
import dayjs from 'dayjs'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'
import helpers from 'utils/helpers'
import LogsView from './LogsView'

function LogsManager () {
  const { t } = useTranslation()
  const { logoutUser, user, updatePageName } = useContext(AuthContext)
  const manager = user?.permission?.find(e => e.manager === 'logs') ?? {}
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [logs, setAllLogs] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [isDelete, setIsDelete] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [isInitialized, setIsInitialized] = useState(false)
  const [filterData, setFilterData] = useState({
    category: '',
    categoryType: '',
    startDate: '',
    endDate: '',
    isReset: false,
    isFilter: false
  })
  const [sort, setSort] = useState({
    sortKey: 'createdAt',
    sortType: 'desc'
  })
  const [logsView, setLogsView] = useState(false)
  const [item, setItem] = useState('')

  const handlePaginationAndDelete = data => {
    const shouldUpdatePaginationAndDelete =
      (data?.deletePage && logs?.length >= 1) ||
      (filterData.isFilter &&
        filterData.category &&
        filterData.categoryType &&
        data?.statusChange &&
        logs?.length >= 1)

    if (shouldUpdatePaginationAndDelete) {
      if (logs?.length >= 1) {
        setPageAndDelete()
      }
    } else {
      setIsDelete(false)
    }
  }

  const setPageAndDelete = () => {
    setPage(page - 1)
    setIsDelete(true)
    setPaginationObj({ ...paginationObj, page: page - 1 })
  }

  const formatDate = date => (date ? dayjs(date).format('YYYY-MM-DD') : null)

  const buildPayload = () => ({
    page,
    pageSize,
    type: filterData.category,
    deviceType: filterData.categoryType,
    startDate: formatDate(filterData.startDate),
    endDate: formatDate(filterData.endDate),
    sortKey: sort.sortKey,
    sortType: sort.sortType
  })

  const handleApiCall = async () => {
    const payload = buildPayload()
    const path = apiPath.getLogs

    try {
      const result = await apiGet(path, payload)

      if (result?.status === 200) {
        handleApiResult(result.data)
      }
    } catch (error) {
      handleApiError(error)
    }
  }

  const handleApiResult = data => {
    const response = data?.results
    const resultStatus = data?.success

    setAllLogs(response?.docs)
    setPaginationObj({
      ...paginationObj,
      page: resultStatus ? response.page : null,
      pageCount: resultStatus ? response.totalPages : null,
      perPageItem: resultStatus ? response?.docs.length : null,
      totalItems: resultStatus ? response.totalDocs : null
    })
  }

  const handleApiError = error => {
    console.error('error ', error)
    setPaginationObj({})

    if (error.response?.status === 401 || error.response?.status === 409) {
      logoutUser()
    }
  }

  const getAllLogs = async data => {
    try {
      handlePaginationAndDelete()
      await handleApiCall()
    } catch (error) {
      console.error('error: ', error)
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  useEffect(() => {
    getAllLogs()
  }, [page, filterData, sort])

  useEffect(() => {
    updatePageName(t('LOGS_MANAGEMENT'))
  }, [])

  const handleReset = () => {
    setFilterData({
      category: '',
      categoryType: '',
      startDate: '',
      endDate: '',
      isReset: true,
      isFilter: false
    })
    setPage(1)
    setIsDelete(true)
    setSearchTerm('')
  }
  const statusPage = e => {
    setFilterData({
      ...filterData,
      isFilter: true,
      isReset: false,
      category: e.target.value
    })
    setPage(1)
    setIsDelete(true)
  }

  const handleView = items => {
    setItem(items)
    setLogsView(!logsView)
  }

  const statusPageType = e => {
    setFilterData({
      ...filterData,
      isFilter: true,
      isReset: false,
      categoryType: e.target.value
    })
    setPage(1)
    setIsDelete(true)
  }
  const handleDateChange = (start, end) => {
    setPage(1)
    setFilterData({
      ...filterData,
      isFilter: true,
      isReset: false,
      startDate: start,
      endDate: end
    })
    setIsDelete(true)
  }

  useEffect(() => {
    helpers.setDebouncingFunction({
      isInitialized,
      setIsInitialized,
      debouncedSearchTerm,
      searchTerm,
      filterData,
      setFilterData,
      setPage
    })
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm.trim())
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  return (
    <div>
      <div className='bg-[#F9F9F9] dark:bg-LightBlue'>
        <div className='px-3 py-4'>
          <div className='bg-white rounded-lg border border-[#E9EDF9] dark:bg-gray-800'>
            <form className='px-4 py-3 pt-5 flex flex-wrap border-b border-b-[#E3E3E3] justify-between'>
              <div className='flex flex-wrap  items-center'>
                <div className='flex items-center lg:pt-0 pt-3 justify-center'>
                  <ODateRangePicker
                    handleDateChange={handleDateChange}
                    isReset={filterData?.isReset}
                    setIsReset={setFilterData}
                  />
                  <div className='flex items-center mb-3 ml-3'>
                    <select
                      name='floating_password'
                      id='countries'
                      type=' password'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 dark:border-[#fff] rounded-lg border-[#DFDFDF]  dark:text-[#A5A5A5] dark:border-gray-600'
                      value={filterData?.category}
                      onChange={e => statusPage(e)}
                      placeholder=' '
                    >
                      <option defaultValue value=''>
                        {t('TYPE')}
                      </option>
                      <option value='info'>{t('INFO')}</option>
                      <option value='error'>{t('ERROR')}</option>
                    </select>
                  </div>
                  <div className='flex items-center mb-3 ml-3'>
                    <select
                      type=' password'
                      id='countries'
                      name='floating_password'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 dark:border-[#fff] rounded-lg border-[#DFDFDF]  dark:text-[#A5A5A5] dark:border-gray-600'
                      placeholder=' '
                      onChange={e => statusPageType(e)}
                      value={filterData?.categoryType}
                    >
                      <option defaultValue value=''>
                        {t('DEVICE_TYPE')}
                      </option>
                      <option value='iOS'>{t('IOS')}</option>
                      <option value='android'>{t('ANDROID')}</option>
                    </select>
                  </div>
                  <button
                    className='rounded-lg items-center border border-transparent bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                    type='button'
                    title={t('O_RESET')}
                    onClick={() => handleReset()}
                  >
                    {t('O_RESET')}
                  </button>
                </div>
              </div>
            </form>
            <Table
              logs={logs}
              user={user}
              getAllLogs={getAllLogs}
              handleView={handleView}
              page={page}
              setSort={setSort}
              sort={sort}
              setPage={setPage}
              manager={manager}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
      <>
        {logsView && (
          <LogsView
            logsView={logsView}
            item={item}
            handleView={handleView}
            getAllLogs={getAllLogs}
          />
        )}
      </>
    </div>
  )
}
export default LogsManager
