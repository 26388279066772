export const validationRules = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
  passwordMessage:
    'Password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.',
  confirmPasswordMessage:
    'Confirm password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.',
  newPasswordMessage:
    'New password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.',
  characters: /^[a-zA-Z_ ]*$/,
  charactersMessage: 'Only alphabets are allowed.',
  numbers: /^[0-9]*$/,
  // removeWhitespace: /^(?=.*[a-zA-Z @./#&ËÇeç+-])(?=.*[0-9])[a-zA-Z0-9 @./#&+-]*$/
  removeWhitespace: /^[a-zA-Z0-9][a-zA-Z0-9 \^\\\-!@#$%&*ËÇeç()_~.+,/'"]+$/gm,
  // mobile:/^(9|8|7)\d{11}$/,
  httpLinkRegex : /^https?:\/\/(?:www\.)?[^\s/$.?#].[^\s]*$/i

}
