import React from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { isEmpty } from 'lodash'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import helpers from '../../utils/helpers'
import { Link } from 'react-router-dom'
import { AiFillEdit } from 'react-icons/ai'

const Table = ({ emailTemplate, getEmailTemplate, user, page, manager }) => {
  const { t } = useTranslation()
  const notification = useToastContext()

  const handelStatusChange = async item => {
    try {
      const payload = {
        status: item.status === 'active' ? 'inactive' : 'active'
      }
      const path = `${apiPath.changeEmailStatus}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        getEmailTemplate()
      }
      // }
    } catch (error) {
      console.error('error in get all users list==>>>>', error.message)
    }
  }

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6 cursor-pointer'>
                <div className='flex justify-left'>
                  <span>Template title</span>
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                <div>
                  <span>Subject</span>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-right'>
                <div className='flex'>
                  <span>Created date</span>
                </div>
              </th>
              <th scope='col' className='py-3 px-6 text-center'>
                Status
              </th>
              {(manager?.edit || user?.role === 'admin') && (
                <th scope='col' className='py-3 px-6 text-center'>
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {emailTemplate &&
              emailTemplate?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 text-gray-900 border-r'>
                    {`${item?.title}` || 'NA'}{' '}
                  </td>
                  <td className='text-gray-900 py-4 px-6 border-r'>
                    {item?.subject || 'NA'}
                  </td>
                  <td className='py-4 px-6 border-r text-gray-900'>
                    {helpers.dateFormat(item?.createdAt) || 'NA'}
                  </td>
                  <td className='py-4 px-6 border-r text-center'>
                    <label
                      className='inline-flex relative items-center cursor-pointer'
                      title={`${
                        item?.status === 'active' ? 'Active' : 'Inactive'
                      }`}
                    >
                      <input
                        type='checkbox'
                        className='sr-only peer'
                        checked={item?.status === 'active'}
                        onChange={e =>
                          helpers.alertFunction(
                            `Are you sure want to ${
                              e.target.checked ? 'active' : 'inactive'
                            } ${item.title}`,
                            item,
                            handelStatusChange
                          )
                        }
                      />
                      <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                    </label>
                  </td>

                  <td className='py-4 px-6 border-l flex justify-center'>
                    {(manager?.edit || user?.role === 'admin') && (
                      <Link
                        to='/emailTemplate/edit'
                        state={{ item }}
                        title={t('EDIT_EMAIL_TEMPLATE')}
                      >
                        <div className=''>
                          <AiFillEdit className='cursor-pointer w-5 h-5 text-slate-600' />
                        </div>
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            {isEmpty(emailTemplate) ? (
              <tr className='bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={9}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
