import { useTranslation } from 'react-i18next'
import OImage from 'components/reusable/OImage'
import defaultImage from '../../assets/images/No-image-found.jpg'
import { startCase } from 'lodash'
import React, { useState, useEffect, useContext } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { IoArrowBackSharp } from 'react-icons/io5'
import AuthContext from 'context/AuthContext'
import fullNameIcon from '../../assets/icons/fullName.svg'
import InfoIcon from '../../assets/icons/info.svg'
import emailIcon from '../../assets/icons/email.svg'
import { AiOutlineDownload } from 'react-icons/ai'
import { saveAs } from 'file-saver'

const ReplyViewMessage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [item] = useState(location.state)
  const { updatePageName } = useContext(AuthContext)

  useEffect(() => {
    updatePageName(t('VIEW_SUPPORT'))
  }, [])

  const downloadImage = () => {
    saveAs(item?.image, 'image.jpg')
  }

  const ProfileInfo = ({ items }) => {
    return (
      <>
        <div className='flex items-center'>
          <div className='dark:bg-LightBlue dark:text-white mb-4 grid grid-cols-4 bg-[#F2F2F2] rounded-lg p-4 w-[calc(100%_-_135px)] mr-4 px-8 flex justify-between'>
            <div className='flex items-center'>
              <figure className='bg-white w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={fullNameIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5 C5C5C]'>Title</span>
                <strong className='text-sm'> {startCase(item?.title)}</strong>
              </figcaption>
            </div>

            <div className='flex items-center'>
              <figure className='bg-white w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={emailIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5 C5C5C]'>Email ID</span>
                <strong className='text-sm'> {items?.email || 'N/A'}</strong>
              </figcaption>
            </div>
            <div className='flex items-center'>
              <figure className='bg-white w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5 C5C5C]'>Transaction ID</span>
                <strong className='text-sm'>
                  {' '}
                  {items?.transaction?.transactionId || 'N/A'}
                </strong>
              </figcaption>
            </div>
          </div>
        </div>
      </>
    )
  }

  const UserDetails = ({ items, translate }) => {
    return (
      <>
        <div className='user-details'>
          <div className='border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full'>
            <div className='flex items-center mb-4'>
              <figure className='bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5C5C5C] dark:text-white'>
                  {translate('TRANSACTION_SELECTED_BY_THE_USER')}
                </span>
                <strong className='text-sm'>
                  {startCase(item?.issueType) || 'N/A'}
                </strong>
              </figcaption>
            </div>

            <div className='flex items-center mb-4'>
              <figure className='bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5C5C5C] dark:text-white'>
                  {translate('DESCRIPTION')}
                </span>
                <strong className='text-sm'>{item?.description}</strong>
              </figcaption>
            </div>
          </div>
        </div>
      </>
    )
  }

  const ImageInfo = ({ items, translate }) => {
    return (
      <>
        <div className='user-details'>
          <div className='border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full'>
            <div className='relative'>
              <figure className='inline-block mb-3 w-full h-[200px]'>
                <OImage
                  src={item?.image || defaultImage}
                  className='w-full h-full object-contain inline '
                  alt=''
                  fallbackUrl={defaultImage}
                />
                <div className='flex items-center justify-center'>
                  <button
                    title='Download'
                    onClick={downloadImage}
                    type='button'
                    className='bg-LightBlue text-sm px-2 mr-3 mt-2 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue'
                  >
                    <AiOutlineDownload
                      style={{ width: '15px', fontWeight: 800 }}
                    />
                  </button>
                  <strong className='block text-sm'>
                    {translate('DOWNLOAD_FILE')}
                  </strong>
                </div>
              </figure>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
 <div className='p-4'>
     <div className='p-5 dark:bg-gray-800 rounded-md'>
      <Link to='/support-manager' className='mb-5 ml-4 block dark:text-white' title='Back'>
        <IoArrowBackSharp />
      </Link>

      <div className=''>
        <ProfileInfo items={item} />
        <div className='grid grid-cols-3 gap-5 dark:text-white'>
          <UserDetails items={item} translate={t} />
          <ImageInfo items={item} translate={t} />
        </div>
      </div>
    </div>
 </div>
  )
}

export default ReplyViewMessage
