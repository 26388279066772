import React, { useEffect, useState } from 'react'
import { apiGet } from '../../../utils/apiFetch'
import apiPath from '../../../utils/pathObj'
import dayjs from 'dayjs'
import Pagination from '../../Pagination'
import { useTranslation } from 'react-i18next'
import helpers from '../../../utils/helpers'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import ViewRemittanceTransactionList from './ViewRemittanceTransactionList'

const ViewRemittanceTransaction = ({ manager }) => {
  const { t } = useTranslation()
  const [pageSize] = useState(10)
  const [page, setPage] = useState(1)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [transactions, setTransactions] = useState([])
  const [filterData, setFilterData] = useState({
    startDate: '',
    endDate: '',
    category: '',
    searchKey: '',
    isReset: false,
    isFilter: false
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [isInitialized, setIsInitialized] = useState(false)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [sort, setSort] = useState({
    sortKey: 'createdAt',
    sortType: 'desc'
  })

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const getRemittanceTransactions = async data => {
    try {
      const { category, startDate, endDate, searchKey, isFilter } = filterData
      if (
        (data?.deletePage && transactions?.length >= 1) ||
        (isFilter &&
          category &&
          data?.statusChange &&
          transactions?.length >= 1)
      ) {
        setPage(page - 1)
        setPaginationObj({ ...paginationObj, page: page - 1 })
      }

      const payload = {
        page,
        pageSize,
        startDate: helpers?.ternaryCondition(
          startDate,
          dayjs(startDate).format('YYYY-MM-DD'),
          null
        ),
        endDate: helpers?.ternaryCondition(
          endDate,
          dayjs(endDate).format('YYYY-MM-DD'),
          null
        ),
        keyword: searchKey?.trim(),
        sortKey: sort.sortKey,
        sortType: sort.sortType
      }
      const path = apiPath.getRemittanceTransactions
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        const resultStatus = result?.data?.success
        setTransactions(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: helpers?.ternaryCondition(resultStatus, response.page, null),
          pageCount: helpers?.ternaryCondition(
            resultStatus,
            response.totalPages,
            null
          ),
          perPageItem: helpers?.ternaryCondition(
            resultStatus,
            response?.docs.length,
            null
          ),
          totalItems: helpers?.ternaryCondition(
            resultStatus,
            response.totalDocs,
            null
          )
        })
      }
    } catch (error) {
      console.log('error ', error)
      setPaginationObj({})
    }
  }

  useEffect(() => {
    helpers.setDebouncingFunction({
      isInitialized,
      setIsInitialized,
      setFilterData,
      setPage,
      debouncedSearchTerm,
      searchTerm,
      filterData
    })
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  useEffect(() => {
    getRemittanceTransactions()
  }, [page, filterData, sort])

  const handleReset = () => {
    setFilterData({
      startDate: '',
      endDate: '',
      category: '',
      searchKey: '',
      isReset: true,
      isFilter: false
    })
    setPage(1)
    setSearchTerm('')
  }

  const handleDateChange = (start, end) => {
    setPage(1)
    setFilterData({
      ...filterData,
      startDate: start,
      endDate: end,
      isReset: false,
      isFilter: true
    })
  }

  return (
    <>
      {' '}
      <div className='bg-[#F9F9F9]'>
        <div className='border-[#E9EDF9] bg-white border rounded-lg'>
          <div className='flex-wrap border-b border-b-[#E3E3E3] 2xl:flex gap-2 px-4 py-3 justify-between'>
            <div className='items-center flex flex-wrap mb-2 2xl:mb-0'>
              <div className='flex-wrap flex items-center lg:pt-0 pt-3 '>
                <ODateRangePicker
                  isReset={filterData?.isReset}
                  handleDateChange={handleDateChange}
                  setIsReset={setFilterData}
                />
                <button
                  onClick={handleReset}
                  type='button'
                  title={t('O_RESET')}
                  className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                >
                  {t('O_RESET')}
                </button>
              </div>
            </div>
            <div className='relative flex items-center mb-3 w-[400px]'>
              <div className='absolute inset-y-0 right-0 pointer-events-none flex items-center pl-3 mr-3'>
                {!searchTerm ? (
                  <svg
                    aria-hidden='true'
                    className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    stroke='currentColor'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth='2'
                      d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                    />
                  </svg>
                ) : (
                  ''
                )}
              </div>
              <input
                type='search'
                name='keyword'
                id='default-search'
                className='block w-full p-2 outline-none rounded-lg text-sm text-gray-900 2xl:min-w-[250px] xl:min-w-[300px] bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder='Sender name & country wise'
                value={searchTerm}
                title=''
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <ViewRemittanceTransactionList
            setSort={setSort}
            sort={sort}
            remittanceTransactions={transactions}
            page={page}
            setPage={setPage}
            manager={manager}
          />
          {paginationObj?.totalItems && (
            <Pagination
              handlePageClick={handlePageClick}
              options={paginationObj}
              page={page}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ViewRemittanceTransaction
