import React from 'react'
import { isEmpty, startCase } from 'lodash'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { BsArrowUpShort } from 'react-icons/bs'

const NotificationTable = ({ notifications, setSort, page, sort }) => {
  const { t } = useTranslation()

  return (
    <div className='p-3'>
      <div className='border relative overflow-x-auto rounded-lg'>
        <table className='w-full dark:text-gray-400 text-xs text-left text-[#A5A5A5]'>
          <thead className='text-xs bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400 text-gray-900 border-[#E1E6EE] border'>
            <tr>
              <th scope='col' className='py-3 px-2'>
                #
              </th>
              <th scope='col' className='px-2 py-3 '>
                {t('O_TITLE')}
              </th>
              <th scope='col' className='py-3 px-2'>
                {t('O_MESSAGE')}
              </th>
              <th className='px-2 py-3' scope='col'>
                {t('O_SEND_TO')}
              </th>

              <th
                className='cursor-pointer py-3 px-2 '
                scope='col'
                onClick={() => {
                  if (sort.sortType === 'asc' && sort.sortKey === 'createdAt') {
                    setSort({
                      sortKey: 'createdAt',
                      sortType: 'desc'
                    })
                  } else {
                    setSort({
                      sortKey: 'createdAt',
                      sortType: 'asc'
                    })
                  }
                }}
              >
                <div className='flex'>
                  <span>{t('CREATED_DATE')}</span>
                  <span>
                    {sort.sortKey === 'createdAt' &&
                      sort.sortType === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sortType === 'desc' &&
                      sort.sortKey === 'createdAt' && (
                        <BsArrowUpShort className='rotate-180 h-4 w-4' />
                      )}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {notifications &&
              notifications?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-2 px-2 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-2 px-2 border-r'>
                    {startCase(item?.title) || t('O_N/A')}
                  </td>
                  <td className='px-2 border-r py-2'>
                    <div
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    ></div>
                  </td>
                  <td className='py-2 px-2 border-r'>
                    {item?.sendTo || t('O_N/A')}
                  </td>
                  <td className='py-2 px-2 border-r text-right'>
                    {dayjs(item?.createdAt).format('DD-MM-YYYY hh:mm A')}
                  </td>
                </tr>
              ))}
            {isEmpty(notifications) ? (
              <tr className='border-b dark:bg-gray-800 bg-white text-center dark:border-gray-700'>
                <td colSpan={5} className='py-2 border-r px-2'>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default NotificationTable
