import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import StaticContentTable from './StaticContentTable'
import Pagination from '../Pagination'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'

function StaticContent () {
  const { t } = useTranslation()
  const { logoutUser, user, updatePageName } = useContext(AuthContext)
  const manager =
    user?.permission?.find(e => e.manager === 'manage-static-contents') ?? {}
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })

  const [staticContentList, setStaticContentList] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)

  const [sort, setSort] = useState({
    sortKey: 'createdAt',
    sortType: 'desc'
  })

  // get all user function-
  const getStaticContentList = async () => {
    try {
      const payload = {
        page,
        pageSize,
        sortKey: sort.sortKey,
        sortType: sort.sortType
      }

      const path = apiPath.getStaticContent
      const result = await apiGet(path, payload)

      if (result?.status === 200) {
        const response = result?.data?.results
        setStaticContentList(response)
      }
    } catch (error) {
      console.error('error ', error)
      setPaginationObj({})
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  useEffect(() => {
    getStaticContentList()
  }, [page, sort])

  useEffect(() => {
    updatePageName(t('STATIC_CONTENTS'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9] dark:bg-LightBlue'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg dark:bg-gray-800 p-5'>
            <StaticContentTable
              staticContentList={staticContentList}
              user={user}
              getStaticContentList={getStaticContentList}
              page={page}
              setSort={setSort}
              sort={sort}
              setPage={setPage}
              manager={manager}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
export default StaticContent
