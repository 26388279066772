import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { isEmpty } from 'lodash'
import withReactContent from 'sweetalert2-react-content'

const localizedFormat = require('dayjs/plugin/localizedFormat')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)

const calendar = require('dayjs/plugin/calendar')
const MySwal = withReactContent(Swal)
dayjs.extend(calendar)
const helpers = {
  currencyFormat: (number = '') => {
    return new Intl.NumberFormat('en-IN', {}).format(number)
  },
  dateFormat: (date, timeZone) => {
    const convertedDate = new Date(date).toLocaleString(undefined, {
      timeZone: timeZone || 'Asia/Kolkata'
    })

    return convertedDate.toString()
  },
  // timeFormat: date => {
  //   if (date) {
  //     const Date = dayjs(date).format('HH:mm')
  //     return Date
  //   }
  // },
  timeFormat: date => (date ? dayjs(date).format('HH:mm') : null),
  msgDateFormat: (date, timeZone) => {
    const convertedDate = new Date(date).toLocaleDateString(undefined, {
      timeZone: timeZone || 'Asia/Kolkata'
    })

    return convertedDate.toString()
  },
  isInputNumber: event => {
    const char = String.fromCharCode(event.which)
    if (!/[0-9]/.test(char)) {
      event.preventDefault()
    }
  },
  alertFunction: (title, item, changeFunction, deleteIcon) => {
    MySwal.fire({
      // title: <h3>{title}</h3>,
      html: <strong className=''>{title}</strong>,
      icon: deleteIcon ? 'error' : 'warning',
      showConfirmButton: 'Okay',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        changeFunction(item)
      }
    })
  },
  downloadFile: url => {
    if (isEmpty(url)) {
      alert('Invalid URL')
      return false
    }
    const parts = url.split('/')
    const filename = parts[parts.length - 1]
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      const a = document.createElement('a')
      const urls = window.URL.createObjectURL(xhr.response)
      a.href = urls
      a.download = filename
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(urls)
    }
    xhr.send()
  },
  ternaryCondition: (condition, first, second) => {
    return condition ? first : second
  },
  setDebouncingFunction: payload => {
    if (!payload.isInitialized) {
      payload.setIsInitialized(true)
    } else if (payload.searchTerm || !payload.filterData?.isReset) {
      payload.setFilterData({
        ...payload.filterData,
        isReset: false,
        searchKey: payload.debouncedSearchTerm
          ? payload.debouncedSearchTerm
          : '',
        isFilter: payload.debouncedSearchTerm ? true : false
      })
      payload.setPage(1)
    }
  }
}

export default helpers
