// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.flatpickr-months .flatpickr-month {background-color: #8A36FF !important }
.flatpickr-current-month .flatpickr-monthDropdown-months{background-color: #8A36FF !important}
span.flatpickr-weekday {background-color: #8A36FF !important;color:#ffffff!important}
.flatpickr-weekdays{background-color: #8A36FF !important;}
.flatpickr-day.selected{
  background-color: #8A36FF !important;
  border:#8A36FF
}

.myAccountHover:hover,.myAccountHover:focus{background-color:#fff!important;}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;;AAGA,oCAAoC,qCAAqC;AACzE,yDAAyD,oCAAoC;AAC7F,wBAAwB,oCAAoC,CAAC,uBAAuB;AACpF,oBAAoB,oCAAoC,CAAC;AACzD;EACE,oCAAoC;EACpC;AACF;;AAEA,4CAA4C,+BAA+B,CAAC","sourcesContent":["/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=number] {\n  -moz-appearance: textfield;\n}\n\n\n.flatpickr-months .flatpickr-month {background-color: #8A36FF !important }\n.flatpickr-current-month .flatpickr-monthDropdown-months{background-color: #8A36FF !important}\nspan.flatpickr-weekday {background-color: #8A36FF !important;color:#ffffff!important}\n.flatpickr-weekdays{background-color: #8A36FF !important;}\n.flatpickr-day.selected{\n  background-color: #8A36FF !important;\n  border:#8A36FF\n}\n\n.myAccountHover:hover,.myAccountHover:focus{background-color:#fff!important;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
