import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { isEmpty, startCase } from 'lodash'

const ViewTransactionList = ({ transactions, page }) => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='p-3'>
        <div className='overflow-x-auto relative rounded-lg border'>
          <table className='w-full text-xs text-left text-[#6c6c6c] dark:text-gray-400 '>
            <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='py-3 px-6'>
                  #
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('SENDER_NAME')}
                </th>
                <th className='py-3 px-6' scope='col'>
                  {t('SENDER_ID')}
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('RECEIVER_NAME')}
                </th>
                <th className='py-3 px-6' scope='col'>
                  {t('RECEIVER_ID')}
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('TRANSACTION_ID')}
                </th>
                <th className='py-3 px-6' scope='col'>
                  {t('TRANSACTION_DATE_TIME')}
                </th>
                <th scope='col' className='px-6 py-3 '>
                  {t('AMOUNT')}
                </th>
                <th scope='col' className='px-6 py-3 '>
                  {t('NET_AMOUNT')}
                </th>
                <th scope='col' className='px-6 py-3 '>
                  {t('COMMISSION')}
                </th>
                <th scope='col' className='px-6 py-3 '>
                  {t('COMMISSION')} (%)
                </th>
                <th scope='col' className='px-6 py-3'>
                  {t('CURRENCY')}
                </th>
                <th className='py-3 px-6' scope='col'>
                  {t('TRANSACTION_MODE_OF_PAYMENT')}
                </th>
                <th className='px-6 py-3 ' scope='col'>
                  {t('TRANSACTION_TYPE')}
                </th>
                <th className='py-3 px-6' scope='col'>
                  {t('O_STATUS')}
                </th>
              </tr>
            </thead>

            <tbody>
              {transactions &&
                transactions.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                    >
                      <th
                        scope='row'
                        className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                      >
                        {i + 1 + 10 * (page - 1)}
                      </th>
                      <td className='py-4 border-r text-gray-900 px-6'>
                        {item?.userTransactionType === 'deposit'
                          ? item?.receiver?.fullName
                          : item?.sender?.fullName}
                      </td>
                      <td className='text-gray-900 py-4 px-6 border-r'>
                        {item?.userTransactionType === 'deposit'
                          ? item?.receiverId
                          : item?.senderId}
                      </td>
                      <td className='py-4 px-6 text-gray-900 border-r'>
                        {item?.receiver?.fullName
                          ? item?.receiver?.fullName
                          : 'N/A'}
                      </td>
                      <td className='py-4 border-r px-6 text-gray-900'>
                        {item?.receiver?.userId}
                      </td>
                      <td className='py-4 text-gray-900 px-6 border-r'>
                        {item?.transactionId}
                      </td>
                      <td className='text-gray-900 py-4 px-6 border-r'>
                        {item?.createdAt
                          ? dayjs(item?.createdAt).format('DD/MM/YYYY hh:mm A')
                          : 'N/A'}
                      </td>
                      <td className='py-4 px-6 text-gray-900 border-r text-right'>
                        {item?.amount?.toFixed(2)}
                      </td>
                      <td className='py-4 text-gray-900 px-6 text-right border-r'>
                        {item?.netAmount?.toFixed(2)}
                      </td>
                      <td className='py-4 px-6 text-right text-gray-900 border-r'>
                        {item?.commission || 0}
                      </td>
                      <td className='text-right py-4 px-6 border-r'>
                        {item?.commissionPercentage || 0}
                      </td>
                      <td className='text-gray-900 py-4 px-6 border-r'>
                        {item?.currency ? item?.currency : 'N/A'}
                      </td>
                      <td className='py-4 text-gray-900 px-6 border-r'>
                        {startCase(item?.paymentMethod)}
                      </td>
                      <td className='py-4 px-6 border-r text-gray-900'>
                        {startCase(item?.transactionType)}
                      </td>
                      <td className='py-4 text-gray-900 border-r px-6'>
                        {startCase(item?.status)}
                      </td>
                    </tr>
                  )
                })}
              {isEmpty(transactions) ? (
                <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                  <td className='py-4 px-6 border-r' colSpan={15}>
                    {t('O_NO_RECORD_FOUND')}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default ViewTransactionList
