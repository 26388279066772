import React from 'react'

const OButton = ({
  label,
  disabled = false,
  type,
  title,
  onClick,
  extraClasses,
  style
}) => {
  return (
    <button
      className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
      style={style}
      type={type}
      disabled={disabled}
      onClick={onClick}
      title={title}
    >
      {label}
    </button>
  )
}

export default OButton
