import { isEmpty, startCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useContext, useState } from 'react'
import AuthContext from 'context/AuthContext'
import { FaReply } from 'react-icons/fa'
import helpers from 'utils/helpers'
import { AiFillEye } from 'react-icons/ai'
import Reply from './Reply'
import { useNavigate } from 'react-router-dom'

const Table = ({ users, manager, getAllSupport, page, handleView }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const [isReply, setIsReply] = useState(false)
  const [replyUser, setReplyUser] = useState('')

  const handleReply = element => {
    setReplyUser(element)
    setIsReply(true)
  }

  return (
    <>
      <div className='p-3 dark:bg-slate-800 rounded-lg'>
        <div className='overflow-x-auto relative rounded-lg border'>
          <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
            <thead className='text-xs text-gray-900 border dark:bg-gray-700 dark:text-gray-400 border-[#E1E6EE] bg-[#E1E6EE]'>
              <tr>
                <th scope='col' className='py-3 px-6'>
                  #
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('TRANSACTION_SELECTED_BY_THE_USER')}
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('TITLE')}
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('EMAIL_ID')}
                </th>
                <th scope='col' className='px-6 py-3'>
                  {t('O_ACTION')}
                </th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users?.map((item, i) => (
                  <tr
                    key={i}
                    className='bg-white dark:border-gray-700 border-b dark:bg-gray-800'
                  >
                    <th
                      scope='row'
                      className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                    >
                      {i + 1 + 10 * (page - 1)}
                    </th>
                    <td className='py-4 text-gray-900 px-6 border-r'>
                      {startCase(item?.issueType) || 'N/A'}
                    </td>
                    <td className='py-4 px-6 text-gray-900 border-r'>
                      {item?.title}
                    </td>
                    <td className='text-gray-900 py-4 px-6 border-r'>
                      {item?.email}
                    </td>
                    <td className='py-4 px-6 border-l'>
                      <div className=''>
                        <ul className='flex justify-center'>
                          {user?.role === 'admin' && (
                            <li
                              disabled={helpers.ternaryCondition(
                                item?.isReplied === false,
                                false,
                                true
                              )}
                              className={
                                item?.isReplied === false
                                  ? 'px-2 py-2 hover:bg-white hover:text-LightBlue'
                                  : ''
                              }
                              onClick={() => handleReply(item)}
                            >
                              {helpers.ternaryCondition(
                                item?.isReplied === false,
                                <button title={t('REPLY')}>
                                  {' '}
                                  <FaReply className='cursor-pointer w-5 h-5 text-slate-600' />{' '}
                                </button>,
                                null
                              )}
                            </li>
                          )}
                          {(manager?.view || user?.role === 'admin') && (
                            <li
                              onClick={() => {
                                navigate('/support-manager/view', {
                                  state: item
                                })
                              }}
                              className='px-2 py-2 hover:bg-white hover:text-LightBlue dark:hover:bg-transparent'
                            >
                              <a title='View support'>
                                {' '}
                                <AiFillEye className='cursor-pointer w-5 h-5 text-slate-600' />{' '}
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              {isEmpty(users) && (
                <tr className='bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700'>
                  <td className='py-4 px-6 border-r' colSpan={6}>
                    {t('O_NO_RECORD_FOUND')}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isReply ? (
        <Reply
          setIsReply={setIsReply}
          item={replyUser}
          getAllSupport={getAllSupport}
        />
      ) : null}
    </>
  )
}

export default Table
