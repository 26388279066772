import React from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { isEmpty, startCase } from 'lodash'
import dayjs from 'dayjs'
import useToastContext from 'hooks/useToastContext'
import { AiFillEye } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { BsArrowUpShort } from 'react-icons/bs'
import { useNavigate } from 'react-router'
import classNames from 'classnames'
import helpers from '../../utils/helpers'

const Table = ({ users, getAllUser, user, page, sort, setSort, manager }) => {
  const { t } = useTranslation()
  const notification = useToastContext()
  const navigate = useNavigate()
  const handelStatusChange = async item => {
    try {
      const payload = {
        status: item?.status === 'inactive' ? 'active' : 'inactive'
      }
      const path = `${apiPath.changeUserStatus}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        getAllUser({ statusChange: 1 })
      }
      // }
    } catch (error) {
      console.error('error in get all users list==>>>>', error.message)
    }
  }

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('USERS_FULL_NAME')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_MOBILE_NUMBER')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('USER_ID')}
              </th>
              <th
                scope='col'
                className='px-6 py-3 cursor-pointer'
                onClick={() => {
                  if (sort.sortKey === 'createdAt' && sort.sortType === 'asc') {
                    setSort({
                      sortKey: 'createdAt',
                      sortType: 'desc'
                    })
                  } else {
                    setSort({
                      sortKey: 'createdAt',
                      sortType: 'asc'
                    })
                  }
                }}
              >
                <div className='flex justify-start'>
                  <span>{t('JOIN_DATE')}</span>
                  <span>
                    {sort.sortType === 'asc' &&
                      sort.sortKey === 'createdAt' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sortKey === 'createdAt' &&
                      sort.sortType === 'desc' && (
                        <BsArrowUpShort className='w-4 rotate-180 h-4 ' />
                      )}
                  </span>
                </div>
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('ACCOUNT_STATUS')}
              </th>
              <th scope='col' className='py-3 px-6 text-center'>
                {t('O_STATUS')}
              </th>
              <th scope='col' className='py-3 px-6 text-center'>
                {t('O_ACTION')}
              </th>
            </tr>
          </thead>
          <tbody>
            {users &&
              users?.map((item, i) => (
                <tr
                  key={i}
                  className={
                    item?.kycStatus === 'deleted'
                      ? 'font-medium bg-white border-b dark:bg-gray-800 dark:border-[#ffffff38] remove-data'
                      : 'bg-white border-b dark:bg-gray-800 dark:border-[#ffffff38]'
                  }
                  // className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className={
                      'py-4 px-6 border-r font-medium text-gray-900  dark:text-white '
                    }
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 border-r text-gray-900'>
                    {`${item?.firstName} ${item?.lastName}`}{' '}
                  </td>

                  <td className='py-4 px-6 text-gray-900 border-r'>
                    {item?.countryCode && '+' + item?.countryCode}{' '}
                    {item?.mobile || 'N/A'}
                  </td>
                  <td className='text-gray-900 py-4 px-6 border-r text-left'>
                    {item?.userId}
                  </td>
                  <td className='py-4 px-6 border-r text-gray-900'>
                    {dayjs(item?.createdAt).format('DD/MM/YYYY hh:mm A')}{' '}
                  </td>
                  <td className='py-4 px-6 border-r'>
                    <span
                      className={classNames('', {
                        'text-green-600': item?.kycStatus === 'verified',
                        'text-red-600': item?.kycStatus === 'unverified'
                      })}
                    >
                      {startCase(item?.kycStatus)}
                    </span>
                  </td>
                  <td className='py-4 px-6 border-r text-center'>
                    {item?.kycStatus === 'deleted' ? (
                      <span className=''>Deleted</span>
                    ) : (
                      <label
                        className='inline-flex relative items-center cursor-pointer'
                        title={`${helpers?.ternaryCondition(
                          item?.status === 'active',
                          'Active',
                          'Inactive'
                        )}`}
                      >
                        <input
                          type='checkbox'
                          className='sr-only peer'
                          checked={item?.status === 'active'}
                          disabled={item?.kycStatus === 'deleted'}
                          onChange={e =>
                            helpers.alertFunction(
                              `Are you sure you want to ${
                                e.target.checked ? 'active' : 'inactive'
                              } "${item.firstName} ${item?.lastName}" user?`,
                              item,
                              handelStatusChange
                            )
                          }
                        />
                        <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                      </label>
                    )}
                  </td>
                  {(manager?.view || user?.role === 'admin') && (
                    <td className='py-4 border-l px-6 '>
                      <div className=''>
                        <ul className='flex justify-center'>
                          <li
                            onClick={() => {
                              navigate('/user-manager/view', {
                                state: item
                              })
                            }}
                            className='px-2 hover:text-LightBlue py-2 hover:bg-white dark:hover:bg-transparent'
                          >
                            <a title={t('VIEW_USER_MANAGER')}>
                              <AiFillEye className='text-slate-600 cursor-pointer w-5 h-5 ' />{' '}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            {isEmpty(users) ? (
              <tr className='bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={9}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
