import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Pagination from '../Pagination'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'
import NotificationTable from './NotificationTable'
import ViewNotifications from './ViewNotifications'
import NotificationAdd from './NotificationAdd'

function Notification () {
  const { t } = useTranslation()
  const { logoutUser, user, updatePageName } = useContext(AuthContext)
  const manager =
    user?.permission?.find(e => e.manager === 'notification_manager') ?? {}

  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [viewShowModal, setViewShowModal] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [item, setItem] = useState('')
  const [isAddNotificationOpen, setIsAddNotificationOpen] = useState(false)
  const [sort, setSort] = useState({
    sortKey: 'createdAt',
    sortType: 'desc'
  })

  const getAll = async () => {
    try {
      const payload = {
        page,
        pageSize,
        sortKey: sort.sortKey,
        sortType: sort.sortType
      }

      const path = apiPath.notification
      const result = await apiGet(path, payload)
      if (result?.data?.success) {
        const response = result?.data?.results
        const resultStatus = result?.data?.success
        setNotifications(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: resultStatus ? response.page : null,
          pageCount: resultStatus ? response.totalPages : null,
          perPageItem: resultStatus ? response?.docs.length : null,
          totalItems: resultStatus ? response.totalDocs : null
        })
      }
    } catch (error) {
      console.error('error ', error)
      setPaginationObj({})
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handleUserView = items => {
    setItem(items)
    setViewShowModal(true)
  }

  useEffect(() => {
    getAll()
  }, [page])

  useEffect(() => {
    updatePageName(t('O_notifications'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg dark:bg-gray-800'>
            <form className='border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap  justify-end'>
              <div className='lg:pt-0 pt-2'>
                <div className=''>
                  {(manager.add || user.permission?.length === 0) && (
                    <button
                      title={t('ADD_NOTIFICATION')}
                      type='button'
                      className='bg-LightBlue  text-sm px-8 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue whitespace-nowrap'
                      onClick={() => setIsAddNotificationOpen(true)}
                    >
                      + {t('ADD_NOTIFICATION')}
                    </button>
                  )}
                </div>
              </div>
            </form>
            <NotificationTable
              notifications={notifications}
              user={user}
              manager={manager}
              getAll={getAll}
              handleUserView={handleUserView}
              page={page}
              setSort={setSort}
              sort={sort}
              setPage={setPage}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
      <>
        {viewShowModal ? (
          <ViewNotifications
            setViewShowModal={setViewShowModal}
            getAll={getAll}
            item={item}
          />
        ) : null}
        {isAddNotificationOpen ? (
          <NotificationAdd
            setViewShowModal={setViewShowModal}
            setIsAddNotificationOpen={setIsAddNotificationOpen}
            getAllNotifications={getAll}
            item={item}
          />
        ) : null}
      </>
    </div>
  )
}
export default Notification
