import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_blue.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
const pickerClasses = [
  'cursor-pointer',
  'bg-calendar',
  'bg-[right_10px_top_10px]',
  'bg-[length:15px_15px]',
  'bg-no-repeat',
  'flex',
  'flex-row-reverse',
  'border',
  'outline-none',
  'text-gray-900',
  'text-sm',
  'rounded-lg',
  'block',
  'w-full',
  'p-2'
]

const ODateRangePicker = props => {
  const { t } = useTranslation()
  const [startDate, setStartDate] = useState(props?.value?.startDate)
  const [endDate, setEndDate] = useState(props?.value?.endDate)
  const onChangeStartDate = ([date]) => {
    setStartDate(date)
    props?.handleDateChange(date, endDate)
  }
  const onChangeEndDate = ([date]) => {
    setEndDate(date)
    props.handleDateChange(startDate, date)
  }
  const handleReset = () => {
    setStartDate('')
    setEndDate('')
  }
  useEffect(() => {
    if (props?.isReset) {
      handleReset()
    }
  }, [props?.isReset])

  return (
    // <div className='sm:flex items-center text-center sm:text-left px-3 md:px-4 xl:px-7 lg:px-5  md:py-5 py-4 md:py-8 border'>
    <>
      <div className='relative flex items-center mb-3'>
        <label className='mx-2 text-[#B8BBBF] text-xs whitespace-nowrap'>
          {t('O_FROM')}
        </label>
        <Flatpickr
          className={pickerClasses.join(' ')}
          name='startDate'
          placeholder={t('O_START_DATE')}
          value={startDate}
          options={{
            maxDate: endDate,
            dateFormat: 'd-m-Y'
          }}
          onChange={onChangeStartDate}
        />
      </div>
      <div className='relative flex items-center mb-3'>
        <label className='mx-2 text-[#B8BBBF] text-xs whitespace-nowrap'>
          {t('O_TO')}
        </label>
        <Flatpickr
          className={pickerClasses.join(' ')}
          name='endDate'
          placeholder={t('O_END_DATE')}
          value={endDate}
          options={{
            minDate: startDate,
            dateFormat: 'd-m-Y'
          }}
          onChange={onChangeEndDate}
        />
      </div>
    </>
    // </div>
  )
}

export default ODateRangePicker
