import React, { useContext, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import Dashboard from '../assets/images/sidebar_icon1.svg'
import User from '../assets/images/sidebar_icon2.svg'
import Transaction from '../assets/images/transaction_manager.png'
import Logs from '../assets/images/logs.png'
import StaticContent from '../assets/images/static_contents.png'
import EarningManager from '../assets/images/earning_manager.png'
import NotificationManager from '../assets/images/notification_manager.svg'
import SubAdminManager from '../assets/images/subadmin_manager.svg'
import Reports from '../assets/images/reports.png'
import Setting from '../assets/images/sidebar_icon9.svg'
import Logout from '../assets/images/sidebar_icon11.svg'
import { useTranslation } from 'react-i18next'
import helpers from 'utils/helpers'

const classNames = require('classnames')
const Sidebar = () => {
  const { t } = useTranslation()
  const {
    logoutUser,
    user,
    sidebarStatus,
    handleSidebar,
    updatePageName,
    setActiveUser,
    getProfileList,
    profileData
  } = useContext(AuthContext)

  const handleLogout = () => {
    window.confirm('Are you sure to sign-out?') && logoutUser()
  }
  useEffect(() => {
    getProfileList()
  }, [])

  const checkSidebarPermission = arg => {
    if (!user || user.permission.length === 0) {
      return true
    }

    const perIndex = user.permission.findIndex(item => item?.manager === arg)

    return perIndex >= 0 && user.permission[perIndex]?.view
  }

  const generateNavLink = (to, iconSrc, label, onClickHandler) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(
          'flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue',
          {
            active: isActive
          }
        )
      }
      onClick={() => {
        updatePageName(label)
        setActiveUser('')
      }}
    >
      <span className='mr-2'>
        <img src={iconSrc} className='max-w-[18px]' alt='' />
      </span>
      {label}
    </NavLink>
  )

  const getSidebarClassNames = () => {
    const baseClass = 'sidebar lg:block z-10 bg-gradient-to-l'
    const visibilityClass = helpers?.ternaryCondition(
      sidebarStatus === 'open',
      'block',
      'hidden'
    )
    const gradientClass = 'from-gradpurple from-10% to-gradblue to-95%'
    const sizeClass = 'w-[220px] xl:w-[280px] fixed h-full overflow-y-auto'

    return `${baseClass} ${visibilityClass} ${gradientClass} ${sizeClass}`
  }

  return (
    <>
      {' '}
      <div className={getSidebarClassNames()} onClick={handleSidebar}>
        <div className='text-white'>
          <Link
            to='/dashboard'
            onClick={() => updatePageName('Dashboard')}
            className='px-2 py-6 w-full text-center flex justify-center'
          >
            <img src='images/migrapay-logo.png' className='inline' alt='' />
          </Link>
          <div className='profile text-center'>
            <small className='block text-sm'>Welcome</small>
            <strong className='block text-lg overflow-hidden text-ellipsis px-2 whitespace-nowrap '>
              {helpers?.ternaryCondition(
                profileData?.firstName && profileData?.lastName,
                `${profileData?.firstName} ${profileData?.lastName}`,
                'Admin'
              )}
            </strong>
          </div>

          <nav className='pt-4 pb-5 flex flex-col justify-center font-normal text-xs overflow-y-auto'>
            {checkSidebarPermission('dashboard') &&
              generateNavLink('/dashboard', Dashboard, t('NAV_DASHBOARD'))}

            {checkSidebarPermission('sub-admin-manager') &&
              generateNavLink(
                '/sub-admin-manager',
                SubAdminManager,
                t('NAV_SUB_ADMIN_MANAGER')
              )}

            {checkSidebarPermission('user-manager') &&
              generateNavLink('/user-manager', User, t('USER_MANAGER'))}

            {checkSidebarPermission('transaction-manager') &&
              generateNavLink(
                '/transaction-manager',
                Transaction,
                t('NAV_TRANSACTION_MANAGER')
              )}
            {checkSidebarPermission('reports-and--analytics-manager') &&
              generateNavLink(
                '/reports-manager',
                Transaction,
                t('NAV_REPORTS_MANAGER')
              )}

            {checkSidebarPermission('support-manager') &&
              generateNavLink(
                '/support-manager',
                EarningManager,
                t('SUPPORT_MANAGER')
              )}

            {checkSidebarPermission('notification-manager') &&
              generateNavLink(
                '/notification-manager',
                NotificationManager,
                t('NOTIFICATION_MANAGER')
              )}

            {checkSidebarPermission('emailTemplate') &&
              generateNavLink(
                '/emailTemplate',
                EarningManager,
                t('EMAIL_TEMPLATE')
              )}

            {checkSidebarPermission('manage-static-contents') &&
              generateNavLink(
                '/manage-static-contents',
                StaticContent,
                t('STATIC_CONTENTS')
              )}
            {checkSidebarPermission('faqs') &&
              generateNavLink('/faqs', Reports, t('NAV_FAQS'))}
            {checkSidebarPermission('logs') &&
              generateNavLink('/logs', Logs, t('LOGS_MANAGEMENT'))}
            {checkSidebarPermission('global-setting') &&
              generateNavLink('/global-setting', Setting, t('NAV_SETTINGS'))}

            <Link
              to='/login'
              onClick={handleLogout}
              className='flex items-center px-4 lg:px-7 py-4 text-white active:text-LightBlue hover:bg-white hover:text-LightBlue'
            >
              <span className='mr-2'>
                <img src={Logout} className='max-w-[18px]' alt='' />
              </span>
              {t('NAV_LOGOUT')}
            </Link>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Sidebar
