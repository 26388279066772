import React, { useEffect, useState } from 'react'
import { apiGet } from '../../../utils/apiFetch'
import apiPath from '../../../utils/pathObj'
import dayjs from 'dayjs'
import Pagination from '../../Pagination'
import { useTranslation } from 'react-i18next'
import helpers from '../../../utils/helpers'
import TransactionHistoryList from './TransactionHistoryList'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { isEmpty } from 'lodash'

const TransactionHistory = ({ manager }) => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [paginationObj, setPaginationObj] = useState({
    pageRangeDisplayed: 10,
    page: 1,
    pageCount: 1
  })
  const [isInitialized, setIsInitialized] = useState(false)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [transactions, setTransactions] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filterData, setFilterData] = useState({
    startDate: '',
    endDate: '',
    isReset: false,
    isFilter: false
  })
  const [sort, setSort] = useState({
    sortKey: 'createdAt',
    sortType: 'desc'
  })

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const getTransactionsHistory = async data => {
    try {
      const { category, startDate, endDate, isFilter } = filterData
      if (
        (data?.deletePage && transactions?.length >= 1) ||
        (isFilter && category && transactions?.length >= 1)
      ) {
        setPage(page - 1)
        setPaginationObj({ ...paginationObj, page: page - 1 })
      }

      const payload = {
        page,
        pageSize,
        startDate: helpers?.ternaryCondition(
          startDate,
          dayjs(startDate).format('YYYY-MM-DD'),
          null
        ),
        endDate: helpers?.ternaryCondition(
          endDate,
          dayjs(endDate).format('YYYY-MM-DD'),
          null
        ),
        sortKey: sort.sortKey,
        sortType: sort.sortType
      }
      const path = apiPath.getTransactionsHistory
      const result = await apiGet(path, payload)
      const response = result?.data?.results
      if (!isEmpty(response)) {
        const resultStatus = result?.data?.success
        setTransactions(response?.docs)
        setPaginationObj({
          ...paginationObj,
          page: helpers?.ternaryCondition(resultStatus, response.page, null),
          pageCount: helpers?.ternaryCondition(
            resultStatus,
            response.totalPages,
            null
          ),
          perPageItem: helpers?.ternaryCondition(
            resultStatus,
            response?.docs.length,
            null
          ),
          totalItems: helpers?.ternaryCondition(
            resultStatus,
            response.totalDocs,
            null
          )
        })
      } else {
        setPaginationObj({})
        setTransactions([])
      }
    } catch (error) {
      console.log('error in get all transactions list==>>>>', error.message)
    }
  }

  useEffect(() => {
    getTransactionsHistory()
  }, [page, filterData, sort])

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true)
    } else if (searchTerm || !filterData?.isReset) {
      setFilterData({
        ...filterData,
        isReset: false,
        searchKey: helpers?.ternaryCondition(
          debouncedSearchTerm,
          debouncedSearchTerm,
          ''
        ),
        isFilter: helpers?.ternaryCondition(debouncedSearchTerm, true, false)
      })
      setPage(1)
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm)
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  const handleReset = () => {
    setFilterData({
      startDate: '',
      endDate: '',
      isReset: true,
      isFilter: false
    })
    setPage(1)
    setSearchTerm('')
  }

  const handleDateChange = (start, end) => {
    setPage(1)
    setFilterData({
      ...filterData,
      isFilter: true,
      isReset: false,
      startDate: start,
      endDate: end
    })
  }

  return (
    <>
      {' '}
      <div className='bg-[#F9F9F9]'>
        <div className='bg-white rounded-lg border border-[#E9EDF9]'>
          <div className='justify-between 2xl:flex gap-2 px-4 py-3 flex-wrap border-b border-b-[#E3E3E3]'>
            <div className='items-center mb-2 2xl:mb-0 flex flex-wrap'>
              <div className='flex items-center lg:pt-0 pt-3 flex-wrap '>
                <ODateRangePicker
                  isReset={filterData?.isReset}
                  setIsReset={setFilterData}
                  handleDateChange={handleDateChange}
                />
                <button
                  onClick={handleReset}
                  className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  type='button'
                  title={t('O_RESET')}
                >
                  {t('O_RESET')}
                </button>
              </div>
            </div>
          </div>
          <TransactionHistoryList
            transactions={transactions}
            page={page}
            getTransactionsHistory={getTransactionsHistory}
            setSort={setSort}
            sort={sort}
            setPage={setPage}
            manager={manager}
          />
          {paginationObj?.totalItems && (
            <Pagination
              handlePageClick={handlePageClick}
              options={paginationObj}
              page={page}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default TransactionHistory
