import 'chartjs-adapter-date-fns'
import Chart from 'react-apexcharts'
import { useState, useEffect } from 'react'
import { apiGet } from 'utils/apiFetch'
import pathObj from 'utils/pathObj'
import { IoIosArrowForward } from 'react-icons/io'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from 'chart.js'
import useToastContext from 'hooks/useToastContext'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
)

const UserActivityList = () => {
  const notification = useToastContext()
  const getDefaultChartData = () => ({
    options: {
      chart: {
        id: 'basic-bar'
      },
      xaxis: {
        categories: []
      }
    },
    series: [
      {
        name: 'User login',
        data: []
      }
    ]
  })

  const getDefaultLineData = () => ({
    series: [
      {
        name: 'Traffic',
        data: [],
        tickAmount: 100
      }
    ],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: '',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        }
      },
      xaxis: {
        categories: []
      }
    }
  })

  const [chartData, setChartData] = useState(getDefaultChartData())
  const [lineData, setLineData] = useState(getDefaultLineData())
  const [transaction, setTransactions] = useState()
  const [isShowGraphData, setIsShowGraphData] = useState(true)
  const [isShowLineData, setIsShowLineData] = useState(false)
  const [active, setActive] = useState('user')

  const handleToggleData = () => {
    setIsShowGraphData(true)
    setIsShowLineData(false)
    setActive('user')
  }
  const handleToggleLineData = () => {
    setIsShowLineData(true)
    setIsShowGraphData(false)
    setActive('traffic')
  }

  const handleGraphApiCall = async () => {
    try {
      const path = pathObj.getUserActivityGraph
      const result = await apiGet(path, {})
      if (result?.data?.success) {
        setTransactions(result?.data?.results)
        const newLIneCategories = result?.data?.results?.traffic?.[0]
        let newLineData = result?.data?.results?.traffic?.[1]
        const newCategories = result?.data?.results?.userLoginXAxis
        let newData = result?.data?.results?.userLoginYAxis
        setChartData(prevData => ({
          ...prevData,
          options: {
            ...prevData?.options,
            xaxis: {
              ...prevData?.options?.xaxis,
              categories: newCategories
            }
          },
          series: [
            {
              ...prevData?.series[0],
              data: newData
            }
          ]
        }))
        setLineData(prevLineData => ({
          ...prevLineData,
          options: {
            ...prevLineData?.options,
            xaxis: {
              ...prevLineData?.options?.xaxis,
              categories: newLIneCategories
            }
          },
          series: [
            {
              ...prevLineData?.series[0],
              data: newLineData
            }
          ]
        }))
      } else {
        notification.error(result?.data?.message)
      }
    } catch (error) {
      console.error('error:', error)
    }
  }

  useEffect(() => {
    handleGraphApiCall()
  }, [])

  return (
    <div>
      <div className='p-3'>
        <div class='grid grid-cols-3 gap-4'>
          <div className=' '>
            <ul className='col-span-2'>
              <li
                className={
                  active === 'user'
                    ? 'p-3 mb-4 cursor-pointer flex items-center justify-between bg-[#f5f5f5] bg-gradblue text-white'
                    : 'p-3 mb-4 cursor-pointer flex items-center justify-between bg-[#f5f5f5]'
                }
                onClick={() => handleToggleData()}
              >
                <strong className='block'>User Activity Metrics</strong>
                <IoIosArrowForward className='text-lg' />
              </li>

              <li className='bg-[#f5f5f5] p-3 mb-4 flex items-center justify-between'>
                <strong className=''>Active Users</strong>{' '}
                <span>({transaction?.activeUser})</span>
              </li>
              <li className='bg-[#f5f5f5] p-3 mb-4 flex items-center justify-between'>
                <strong className='block'>
                  {' '}
                  New User Registered <span className=''>(In last 20days)</span>
                </strong>{' '}
                <span>({transaction?.newUserLastTwentyDays})</span>
              </li>
              <li
                className={
                  active === 'traffic'
                    ? 'p-3 mb-4 cursor-pointer flex items-center justify-between bg-[#f5f5f5] bg-gradblue text-white'
                    : 'p-3 mb-4 cursor-pointer flex items-center justify-between bg-[#f5f5f5]'
                }
                onClick={() => handleToggleLineData()}
              >
                <strong className='block'>Traffic analysis</strong>
                <IoIosArrowForward className='text-lg' />
              </li>
            </ul>
          </div>
          <div className='col-span-2'>
            {isShowGraphData && (
              <div className='flex justify-start'>
                <Chart
                  options={chartData.options}
                  series={chartData.series}
                  type='bar'
                  height='400'
                  className='flex w-full'
                />
              </div>
            )}
            {isShowLineData && (
              <div className='flex justify-start'>
                <Chart
                  options={lineData.options}
                  series={lineData.series}
                  type='line'
                  height='400'
                  className='flex w-full'
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserActivityList
