import ErrorMessage from 'components/ErrorMessage'
import React from 'react'

const OTextArea = (props) => {
	const { wrapperClassName, name, inputLabel, type, placeholder, register, errors, ...rest } = props

	return (
		<div className={wrapperClassName ?? 'relative z-0 mb-6 w-full group'}>
			<label htmlFor={name} className='block mb-2 text-sm font-medium text-gray-900 '>
				{inputLabel}
			</label>
			<textarea
				type={type}
				name={name}
				id={name}
				rows='4'
				className='dark:bg-transparent dark:text-white block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500'
				placeholder={placeholder}
				{...register}
				{...rest}
			/>

			<ErrorMessage message={errors?.[name]?.message} />
		</div>
	)
}

export default OTextArea