export const permissionsMap = {
  // dashboard: '/dashboard',
  'user-manager': '/user-manager',
  logs: '/logs',
  'transaction-manager': 'transaction-manager',
  'reports-and--analytics-manager': 'reports-manager',
  category_manager: '/categories',
  subcategory_manager: 'subCategories',
  subadmin_manager: '/sub-admin-manager',
  'support-manager': '/support-manager',
  emailTemplate: '/emailTemplate',
  faqs: 'faqs',
  logs_managers: 'logs-management',
  manage_micro_credit_request: 'manage-micro-credit-request',
  manage_micro_credit_loan_slabs: 'manage-micro-credit-loan-slabs',
  micro_credit_request_settings: 'micro-credit-request-setting',
  manage_orders: 'manage-orders',
  reports: 'reports',
  escrow_manager: '/escrow',
  buy_gift_card_manager: '/buy-gift-card',
  'manage-static-contents': '/manage-static-contents',
  'notification-manager': '/notification-manager',
  'global-setting': '/global-setting',
  banner_manager: '/banner',
  coupons_manager: '/coupons'
}
