import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Table from './Table'
import Pagination from '../Pagination'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'
import Reply from './Reply'
import ReplyViewMessage from './ReplyViewMessage'

function SupportManager () {
  const { t } = useTranslation()
  const { logoutUser, user, updatePageName } = useContext(AuthContext)
  const manager =
    user?.permission?.find(e => e.manager === 'support-manager') ?? {}
  const [replyShowModal, setReplyShowModal] = useState(false)
  const [replyView, setReplyView] = useState(false)
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [users, setAllUser] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [item, setItem] = useState('')
  const [isDelete, setIsDelete] = useState(false)
  const [sort, setSort] = useState({
    sortKey: 'createdAt',
    sortType: 'desc'
  })

  const handleDeleteAndFilter = data => {
    if (
      (data?.deletePage && users?.length >= 1) ||
      (data?.statusChange && users?.length >= 1)
    ) {
      handleUserDelete()
    } else {
      setIsDelete(false)
    }
  }

  const handleUserDelete = () => {
    if (users?.length >= 1) {
      setPageAndPagination(page - 1)
      setIsDelete(true)
    }
  }

  const setPageAndPagination = newPage => {
    setPage(newPage)
    setPaginationObj({ ...paginationObj, page: newPage })
  }

  const getAllSupport = async data => {
    try {
      handleDeleteAndFilter(data)

      const payload = {
        page,
        pageSize,
        sortKey: sort.sortKey,
        sortType: sort.sortType
      }

      const path = apiPath.getSupport
      const result = await apiGet(path, payload)

      handleApiResult(result)
    } catch (error) {
      handleError(error)
    }
  }

  const handleApiResult = result => {
    if (result?.status === 200) {
      const response = result?.data?.results
      const resultStatus = result?.data?.success

      setAllUser(response?.docs)
      setPaginationObj({
        ...paginationObj,
        page: resultStatus ? response.page : null,
        pageCount: resultStatus ? response.totalPages : null,
        perPageItem: resultStatus ? response?.docs.length : null,
        totalItems: resultStatus ? response.totalDocs : null
      })
    }
  }

  const handleError = error => {
    console.log('error ', error)
    setPaginationObj({})

    if (error.response?.status === 401 || error.response?.status === 409) {
      logoutUser()
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  const handleView = items => {
    setItem(items)
    setReplyView(!replyView)
  }

  useEffect(() => {
    getAllSupport()
  }, [page, sort])

  const handelReply = items => {
    setItem(items)
    setReplyShowModal(true)
  }
  useEffect(() => {
    updatePageName(t('SUPPORT_MANAGER'))
  }, [])

  return (
    <div>
      <div className='bg-[#F9F9F9] dark:bg-LightBlue'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg dark:bg-gray-800'>
            <Table
              users={users}
              user={user}
              getAllSupport={getAllSupport}
              page={page}
              handelReply={handelReply}
              handleView={handleView}
              setSort={setSort}
              sort={sort}
              manager={manager}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
      <>
        {replyShowModal ? (
          <Reply
            setReplyShowModal={setReplyShowModal}
            users={users}
            getAllSupport={getAllSupport}
            item={item}
          />
        ) : null}
        {replyView && (
          <ReplyViewMessage
            replyView={replyView}
            item={item}
            handleView={handleView}
            getAllSupport={getAllSupport}
          />
        )}
      </>
    </div>
  )
}
export default SupportManager
