import React, { useContext } from 'react'
import { apiPut, apiDelete, apiPost } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { isEmpty, startCase } from 'lodash'
import { AiFillEdit, AiFillDelete } from 'react-icons/ai'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'
import useToastContext from 'hooks/useToastContext'
import helper from '../../utils/helpers'
import dayjs from 'dayjs'
import movePng from '../../assets/images/move.png'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const Table = ({
  FAQs,
  setFAQS,
  getAllFAQ,
  handelEdit,
  paginationObj,
  sort,
  setSort,
  manager
}) => {
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const notification = useToastContext()

  const handelStatusChange = async item => {
    try {
      const payload = {
        status: item?.status === 'inactive' ? 'active' : 'inactive'
      }
      const path = `${apiPath.changeFAQStatus}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        getAllFAQ()
      }
    } catch (error) {
      console.log('error in get all faqs list==>>>>', error.message)
    }
  }

  const handelDelete = async item => {
    try {
      const path = apiPath.getFAQs + '/' + item?._id
      const result = await apiDelete(path)
      if (result?.status === 200) {
        getAllFAQ()
      }
    } catch (error) {
      console.log('error in get all FAQs list==>>>>', error.message)
    }
  }
  const sequenceSort = async payload => {
    const sequence = payload?.map(element => {
      return {
        _id: element._id,
        sequence: element.sequence,
        title: element.title
      }
    })
    const result = await apiPost(apiPath.updateSequence, {
      sequence: sequence
    })
    console.log('result', result)
  }
  const onDragEnd = result => {
    if (!result.destination) return

    const updatedItems = [...FAQs]
    const [movedItem] = updatedItems.splice(result.source.index, 1)
    updatedItems.splice(result.destination.index, 0, movedItem)
    const updatedSequence = updatedItems.map((element, index) => {
      return { ...element, sequence: index + 1 }
    })
    setFAQS(updatedSequence)
    sequenceSort(updatedSequence)
  }

  return (
    <div className='p-3'>
      <div className='overflow-x-auto relative rounded-lg border'>
        <DragDropContext onDragEnd={onDragEnd}>
          <table className='w-full text-xs text-left text-[#A5A5A5] dark:text-gray-400 '>
            <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
              <tr>
                <th scope='col' className='py-3 px-6'>
                  <div className='text-left'>{t('Drag Row')}</div>
                </th>
                <th scope='col' className='py-3 px-6'>
                  #
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('FAQS_TITLE')}
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('CREATED_DATE')}
                </th>
                <th scope='col' className='py-3 px-6'>
                  {t('O_UPDATE_DATE')}
                </th>

                <th scope='col' className='py-3 px-6 text-center'>
                  {t('O_STATUS')}
                </th>
                {(manager?.edit || user?.role === 'admin') && (
                  <th scope='col' className='py-3 px-6 text-center'>
                    {t('O_ACTION')}
                  </th>
                )}
              </tr>
            </thead>

            <Droppable droppableId='item-list'>
              {provided => (
                <tbody ref={provided?.innerRef} {...provided?.droppableProps}>
                  {FAQs &&
                    FAQs?.map((item, i) => (
                      <Draggable
                        key={item?.id}
                        draggableId={item?.id}
                        index={i}
                      >
                        {provides => (
                          <tr
                            key={i}
                            className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                            ref={provides?.innerRef}
                            {...provides?.draggableProps}
                            {...provides?.dragHandleProps}
                          >
                            <td className='py-4 px-6 border-r h-4 w-4'>
                              <img src={movePng} alt='' className='dark:invert' />
                            </td>
                            <th
                              scope='row'
                              className='py-4 px-6 border-r font-medium text-gray-900 dark:text-white'
                            >
                              {i + 1 + 10 * (paginationObj?.page - 1)}
                            </th>
                            <td className='py-4 px-6 border-r text-gray-900 dark:text-white'>
                              {startCase(item?.title)}
                            </td>
                            <td className='py-4 px-6 border-r text-gray-900 dark:text-white'>
                              {dayjs(item?.createdAt).format(
                                'DD/MM/YYYY hh:mm A'
                              )}{' '}
                            </td>
                            <td className='py-4 px-6 border-r text-gray-900 dark:text-white'>
                              {dayjs(item?.updatedAt).format(
                                'DD/MM/YYYY hh:mm A'
                              )}{' '}
                            </td>

                            <td className='py-4 px-6 border-r text-gray-900 text-center'>
                              <label
                                className='inline-flex relative items-center cursor-pointer'
                                title={`${
                                  item?.status === 'active'
                                    ? 'Active'
                                    : 'Inactive'
                                }`}
                              >
                                <input
                                  type='checkbox'
                                  className='sr-only peer'
                                  checked={item?.status === 'active'}
                                  onChange={e =>
                                    helper.alertFunction(
                                      `Are you sure want to ${
                                        e.target.checked ? 'active' : 'inactive'
                                      } '${item.title}'?`,
                                      item,
                                      handelStatusChange
                                    )
                                  }
                                />
                                <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                              </label>
                            </td>

                            <td className='py-4 px-6 border-l text-gray-900'>
                              <div className=''>
                                <ul className='flex justify-center'>
                                  {(manager?.edit ||
                                    user?.role === 'admin') && (
                                    <li
                                      onClick={() => handelEdit(item)}
                                      className='px-2 py-2 hover:text-LightBlue'
                                    >
                                      <button title={t('FAQS_EDIT_FAQS')} >
                                        {' '}
                                        <AiFillEdit className='w-5 h-5 text-slate-600' />
                                      </button>
                                    </li>
                                  )}
                                  {user?.role === 'admin' && (
                                    <li
                                      onClick={e =>
                                        helper.alertFunction(
                                          `Are you sure want to delete '${item.title}'?`,
                                          item,
                                          handelDelete,
                                          true
                                        )
                                      }
                                      className='px-2 py-2 hover:text-LightBlue'
                                    >
                                      <button title={t('DELETE_FAQS')}>
                                        {' '}
                                        <AiFillDelete className='w-5 h-5 text-red-600' />{' '}
                                      </button>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  {isEmpty(FAQs) && (
                    <tr className='bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700'>
                      <td className='py-4 px-6 border-r' colSpan={6}>
                        {t('O_NO_RECORD_FOUND')}
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
    </div>
  )
}

export default Table
