import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Table from './Table'
import Pagination from '../Pagination'
import AuthContext from 'context/AuthContext'
import dayjs from 'dayjs'
import ODateRangePicker from 'components/shared/datePicker/ODateRangePicker'
import { useTranslation } from 'react-i18next'
import helpers from 'utils/helpers'

function User () {
  const { t } = useTranslation()
  const { logoutUser, user, updatePageName, activeUser } =
    useContext(AuthContext)
  const manager =
    user?.permission?.find(e => e.manager === 'user-manager') ?? {}
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [users, setAllUser] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize] = useState(10)
  const [isDelete, setIsDelete] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [isInitialized, setIsInitialized] = useState(false)
  const [filterData, setFilterData] = useState({
    category: activeUser || '',
    KYC: '',
    searchKey: '',
    startDate: '',
    endDate: '',
    isReset: false,
    isFilter: false
  })
  const [sort, setSort] = useState({
    sortKey: 'createdAt',
    sortType: 'desc'
  })

  const handlePaginationAndDelete = data => {
    const shouldUpdatePaginationAndDelete =
      (data?.deletePage && users?.length >= 1) ||
      (filterData.isFilter &&
        filterData.category &&
        filterData.KYC &&
        data?.statusChange &&
        users?.length >= 1)

    if (shouldUpdatePaginationAndDelete) {
      if (users?.length >= 1) {
        setPageAndDelete()
      }
    } else {
      setIsDelete(false)
    }
  }

  const setPageAndDelete = () => {
    setPage(page - 1)
    setIsDelete(true)
    setPaginationObj({ ...paginationObj, page: page - 1 })
  }

  const formatDate = date => (date ? dayjs(date).format('YYYY-MM-DD') : null)

  const buildPayload = () => ({
    page,
    pageSize,
    status: filterData.category,
    kycStatus: filterData.KYC,
    startDate: formatDate(filterData.startDate),
    endDate: formatDate(filterData.endDate),
    keyword: filterData.searchKey?.trim(),
    sortKey: sort.sortKey,
    sortType: sort.sortType
  })

  const handleApiCall = async () => {
    const payload = buildPayload()
    const path = apiPath.getUsers

    try {
      const result = await apiGet(path, payload)

      if (result?.status === 200) {
        handleApiResult(result.data)
      }
    } catch (error) {
      handleApiError(error)
    }
  }

  const handleApiResult = data => {
    const response = data.results
    const resultStatus = data.success

    setAllUser(response?.docs)
    setPaginationObj({
      ...paginationObj,
      page: resultStatus ? response.page : null,
      pageCount: resultStatus ? response.totalPages : null,
      perPageItem: resultStatus ? response?.docs.length : null,
      totalItems: resultStatus ? response.totalDocs : null
    })
  }

  const handleApiError = error => {
    console.error('error ', error)
    setPaginationObj({})

    if (error.response?.status === 401 || error.response?.status === 409) {
      logoutUser()
    }
  }

  const getAllUser = async data => {
    try {
      handlePaginationAndDelete()
      await handleApiCall()
    } catch (error) {
      console.error('error: ', error)
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  useEffect(() => {
    getAllUser()
  }, [page, filterData, sort])

  useEffect(() => {
    updatePageName(t('USER_MANAGER'))
  }, [])

  const handleReset = () => {
    setFilterData({
      category: '',
      KYC: '',
      searchKey: '',
      startDate: '',
      endDate: '',
      isReset: true,
      isFilter: false
    })
    setPage(1)
    setIsDelete(true)
    setSearchTerm('')
  }
  const handleDateChange = (start, end) => {
    setPage(1)
    setFilterData({
      ...filterData,
      startDate: start,
      endDate: end,
      isFilter: true,
      isReset: false
    })
    setIsDelete(true)
  }

  const statusPage = e => {
    setFilterData({
      ...filterData,
      category: e.target.value,
      isFilter: true,
      isReset: false
    })
    setPage(1)
    setIsDelete(true)
  }
  const statusPageAccount = e => {
    setFilterData({
      ...filterData,
      KYC: e.target.value,
      isFilter: true,
      isReset: false
    })
    setPage(1)
    setIsDelete(true)
  }
  useEffect(() => {
    helpers.setDebouncingFunction({
      isInitialized,
      setIsInitialized,
      setFilterData,
      setPage,
      debouncedSearchTerm,
      searchTerm,
      filterData
    })
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm.trim())
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4'>
          <div className='rounded-lg bg-white border border-[#E9EDF9] '>
            <form className='border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap justify-between'>
              <div className='flex flex-wrap items-center'>
                <div className='lg:pt-0 pt-3 flex items-center justify-center'>
                  <ODateRangePicker
                    handleDateChange={handleDateChange}
                    isReset={filterData?.isReset}
                    setIsReset={setFilterData}
                  />
                  <div className='flex items-center mb-3 ml-3'>
                    <select
                      id='countries'
                      type=' password'
                      name='floating_password'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 dark:border-[#fff] rounded-lg border-[#DFDFDF]  dark:text-[#A5A5A5] dark:border-gray-600'
                      placeholder=' '
                      value={filterData?.category}
                      onChange={e => statusPage(e)}
                    >
                      <option defaultValue value=''>
                        {t('O_ALL')}
                      </option>
                      {[
                        { key: 'O_ACTIVE', value: 'active' },
                        { key: 'O_INACTIVE', value: 'inactive' }
                      ]?.map(item => (
                        <option
                          value={item.value}
                          selected={activeUser === item.value}
                        >
                          {t(item?.key)}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='flex items-center mb-3 ml-3'>
                    <select
                      id='countries'
                      type=' password'
                      name='floating_password'
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 dark:border-[#fff] rounded-lg border-[#DFDFDF]  dark:text-[#A5A5A5] dark:border-gray-600'
                      placeholder=' '
                      value={filterData?.KYC}
                      onChange={e => statusPageAccount(e)}
                    >
                      <option defaultValue value=''>
                        {t('O_ALL')}
                      </option>
                      <option value='verified'>{t('VERIFIED')}</option>
                      <option value='unverified'>{t('UNVERIFIED')}</option>
                    </select>
                  </div>
                  <button
                    type='button'
                    title={t('O_RESET')}
                    onClick={() => handleReset()}
                    className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_RESET')}
                  </button>
                </div>
              </div>
              <div className='flex items-center md:justify-end px-4'>
                <label
                  htmlFor='default-search'
                  className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                >
                  {t('O_SEARCH')}
                </label>
                <div className='flex'>
                  <div className='relative '>
                    <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'>
                      {!searchTerm ? (
                        <svg
                          aria-hidden='true'
                          className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                          fill='none'
                          stroke='currentColor'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                          />
                        </svg>
                      ) : (
                        ''
                      )}
                    </div>
                    <input
                      type='search'
                      id='default-search'
                      className='block w-full p-2 outline-none text-sm text-gray-900 2xl:min-w-[250px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder={t('O_SEARCH_BY_NAME_EMAIL_MOBILE')}
                      value={searchTerm}
                      title=''
                      required
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>
            <Table
              users={users}
              user={user}
              getAllUser={getAllUser}
              page={page}
              setSort={setSort}
              sort={sort}
              setPage={setPage}
              manager={manager}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
export default User
