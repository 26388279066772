import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import AuthContext from 'context/AuthContext'
import ViewTransaction from './viewTransaction/ViewTransaction'
import ViewRemittanceTransaction from './viewRemittanceTransaction/ViewRemittanceTransaction'

const tabBtnClass =
  'inline-block py-3 px-8 text-md xl:text-lg font-semibold border-b-2'
const tabActiveClass = 'border-blue-600 text-blue-600'
const tabBorderNoneClass = 'border-transparent'
const TAB_VIEW_TRANSACTION = 'viewTransaction'
const TAB_VIEW_REMITTANCE_TRANSACTION = 'viewRemittanceTransaction'
const TransactionManager = () => {
  const { t } = useTranslation()
  const { updatePageName, user } = useContext(AuthContext)
  const manager =
    user?.permission?.find(e => e.manager === 'transaction-manager') ?? {}
  const [defaultTab, setDefaultTab] = useState('viewTransaction')
  const handleTabClick = (action, e) => {
    setDefaultTab(action)
  }

  useEffect(() => {
    updatePageName(t('NAV_TRANSACTION_MANAGER'))
  }, [])
  return (
    <div className='py-5 px-9'>
      <div className='py-3 my-2'>
        <ul className='border-b flex'>
          <li className='mr-0 xl:mr-5'>
            <button
              onClick={handleTabClick.bind(this, TAB_VIEW_TRANSACTION)}
              className={classNames({
                [tabBtnClass]: true,
                [tabActiveClass]: defaultTab === TAB_VIEW_TRANSACTION,
                [tabBorderNoneClass]: defaultTab !== TAB_VIEW_TRANSACTION
              })}
            >
              {t('VIEW_TRANSACTION')}
            </button>
          </li>
          <li className='mx-0 xl:mx-5'>
            <button
              onClick={handleTabClick.bind(
                this,
                TAB_VIEW_REMITTANCE_TRANSACTION
              )}
              className={classNames({
                [tabBtnClass]: true,
                [tabActiveClass]:
                  defaultTab === TAB_VIEW_REMITTANCE_TRANSACTION,
                [tabBorderNoneClass]:
                  defaultTab !== TAB_VIEW_REMITTANCE_TRANSACTION
              })}
            >
              {t('VIEW_REMITTANCE_TRANSACTION')}
            </button>
          </li>
        </ul>
      </div>

      {defaultTab === TAB_VIEW_TRANSACTION ? (
        <ViewTransaction manager={manager} />
      ) : (
        defaultTab === TAB_VIEW_REMITTANCE_TRANSACTION && (
          <ViewRemittanceTransaction manager={manager} />
        )
      )}
    </div>
  )
}

export default TransactionManager
