import ErrorMessage from 'components/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import React, { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { apiGet, apiPost } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import AsyncSelect from 'react-select/async'
import AuthContext from 'context/AuthContext'

const NotificationAdd = ({ getAllNotifications, setIsAddNotificationOpen }) => {
  const { t } = useTranslation()
  const { logoutUser } = useContext(AuthContext)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    shouldFocusError: true
  })
  const notification = useToastContext()
  const [addMerchantLoading, setAddMerchantLoading] = useState(false)
  const [sendToError, setSendToError] = useState(false)
  const [sendTo, setSendTo] = useState('all')
  const [isAllUser, setIsAllUser] = useState(true)
  const [selectedUser, setSelectedUser] = useState([])

  const getAllUser = async keyword => {
    try {
      const path = apiPath.getUsers
      const result = await apiGet(path, { keyword })

      if (result?.data?.success) {
        return result?.data?.results?.docs
      } else {
        return []
      }
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }
  const filterColors = async keyword => {
    const result = await getAllUser(keyword)

    return result?.map(item => ({
      label: item?.firstName + ' ' + item?.lastName,
      value: item?._id
    }))
  }

  const promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        if (inputValue) {
          resolve(filterColors(inputValue?.trim()))
        }
      }, 500)
    })
  const loadOptions = useCallback(
    debounce((inputText, callback) => {
      promiseOptions(inputText).then(options => callback(options))
    }, 500),
    []
  )
  const onSubmit = async data => {
    setAddMerchantLoading(true)
    try {
      const isValid = onError()
      if (!isValid) return
      const obj = {
        title: data?.title,
        description: data?.description,
        sendTo,
        user:
          selectedUser?.length > 0
            ? selectedUser?.map(item => item.value)
            : selectedUser
      }
      const res = await apiPost(apiPath.notification, {
        ...obj
      })
      if (res.data.success) {
        setIsAddNotificationOpen(false)
        notification.success(res?.data?.message)
        getAllNotifications()
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.error('err:', err)
    } finally {
      setAddMerchantLoading(false)
    }
  }

  const onError = () => {
    let isValid = true
    if (sendTo === '') {
      setSendToError(true)
      isValid = false
    }
    return isValid
  }
  const handleCheckAllUser = e => {
    setIsAllUser(e.target.checked)
    if (e.target.checked) {
      setSelectedUser([])
      setSendToError(false)
      setSendTo('all')
    } else {
      setSendTo('')
    }
  }
  const onChangeMultiSelectUser = selectedUserList => {
    setSelectedUser(selectedUserList)
    if (selectedUserList?.length === 0) {
      setSendTo('')
    } else {
      setSendTo('specific')
      setSendToError(false)
    }
  }

  const getTitleValidationMessage = (value, t1) => {
    const trimmedValue = value.trim()
    if (trimmedValue.length < 3) {
      return t1('VALIDATION_TITLE_MIN_LENGTH')
    } else if (trimmedValue.length > 30) {
      return t1('VALIDATION_TITLE_MAX_LENGTH')
    } else {
      return true
    }
  }

  return (
    <div>
      <>
        <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
          <div className='relative my-6  w-full max-w-[1150px] mx-auto'>
            <div className='sm:py-4 sm:px-2 py-8 px-7 '>
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h3 className='text-xl font-semibold'>
                    {t('ADD_NOTIFICATION')}
                  </h3>
                  <button
                    className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                    onClick={() => setIsAddNotificationOpen(false)}
                  >
                    <span className=' text-[#B8BBBF]  text-4xl ' title='Close'>
                      ×
                    </span>
                  </button>
                </div>
                <div className='relative p-6 grid grid-cols-2'>
                  <div className=''>
                    <div className='px-2'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <input
                          type='text'
                          name='title'
                          id='title'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          maxLength={31}
                          {...register('title', {
                            required: t('VALIDATION_TITLE_REQUIRED'),
                            validate: value =>
                              getTitleValidationMessage(value, t)
                          })}
                          onChange={event => {
                            var value = event.target.value
                            value = value.trimStart()
                            setValue('title', value, { shouldValidate: true })
                          }}
                        />
                        <label
                          htmlFor='title'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_TITLE')}
                          <span className='text-red-500'>*</span>
                        </label>
                        <ErrorMessage message={errors?.title?.message} />
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <textarea
                          type='text'
                          name='description'
                          id='description'
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          rows={7}
                          maxLength={256}
                          {...register('description', {
                            required: t('VALIDATION_DESCRIPTION_REQUIRED'),
                            validate: value => {
                              const val = value.trim()
                              return val.length < 3
                                ? t('VALIDATION_DESCRIPTION_MIN_LENGTH')
                                : true
                            }
                          })}
                          onChange={event => {
                            var value = event.target.value
                            value = value.trimStart()
                            setValue('description', value, {
                              shouldValidate: true
                            })
                          }}
                        />
                        <label
                          htmlFor='description'
                          className='peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('O_DESCRIPTION')}
                          <span className='text-red-500'>*</span>
                        </label>
                        <ErrorMessage message={errors?.description?.message} />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className='px-2 mb-8'>
                      <div className='flex pb-2'>
                        <h3 className=''>{t('O_SEND_TO')}</h3>
                        <span className='text-red-500'>*</span>
                      </div>
                      <div className='z-0 mb-6 w-full group grid grid-cols-2 gap-y-5'>
                        <div className='flex items-center text-2xl'>
                          <input
                            id='user-checkbox'
                            type='checkbox'
                            checked={isAllUser}
                            onChange={handleCheckAllUser}
                            className='w-5 cursor-pointer h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                          />
                          <label
                            htmlFor='user-checkbox'
                            className='ml-2 text-sm font-medium cursor-pointer text-gray-900 dark:text-gray-300'
                          >
                            {t('ALL_USERS')}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='px-2'>
                      <div className='relative z-0 mb-6 w-full group'>
                        <AsyncSelect
                          className=''
                          isMulti
                          cacheOptions
                          defaultValue={[]}
                          value={selectedUser}
                          onChange={onChangeMultiSelectUser}
                          loadOptions={loadOptions}
                          isDisabled={isAllUser}
                        />
                        {sendToError && (
                          <ErrorMessage
                            message={
                              'Please select all user or any specific user.'
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setIsAddNotificationOpen(false)}
                  >
                    {t('O_BACK')}
                  </button>
                  {addMerchantLoading ? (
                    <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
                      <div className='loading-spinner'></div>
                    </div>
                  ) : (
                    <button
                      title={t('O_ADD')}
                      className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                      type='submit'
                      onClick={handleSubmit(onSubmit, onError)}
                    >
                      {t('O_ADD')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='opacity-25 fixed inset-0 z-40 bg-black' />
      </>
    </div>
  )
}

export default NotificationAdd
