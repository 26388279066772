import React, { useContext, useEffect, useState } from 'react'
import { apiGet } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Table from './Table'
import Pagination from '../Pagination'
import AuthContext from 'context/AuthContext'
import { useTranslation } from 'react-i18next'
import helpers from 'utils/helpers'

function EmailTemplate () {
  const { t } = useTranslation()
  const { logoutUser, user } = useContext(AuthContext)
  const manager =
    user?.permission?.find(e => e.manager === 'emailTemplate') ?? {}
  const [paginationObj, setPaginationObj] = useState({
    page: 1,
    pageCount: 1,
    pageRangeDisplayed: 10
  })
  const [emailTemplate, setEmailTemplate] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [isDelete, setIsDelete] = useState(false)

  const [sort, setSort] = useState({
    sortKey: 'createdAt',
    sortType: 'desc'
  })

  const [searchTerm, setSearchTerm] = useState('')
  const [isInitialized, setIsInitialized] = useState(false)
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

  const [filterData, setFilterData] = useState({
    category: '',
    searchKey: '',
    isReset: false,
    isFilter: false
  })

  const statusPage = e => {
    setFilterData({
      ...filterData,
      category: e.target.value,
      isFilter: true,
      isReset: false
    })
    setPage(1)
    setIsDelete(true)
  }

  const handleReset = () => {
    setFilterData({
      category: '',
      searchKey: '',
      isReset: true,
      isFilter: false
    })
    setPage(1)
    setIsDelete(true)
    setSearchTerm('')
  }

  const handlePaginationAndDelete = data => {
    const shouldUpdatePaginationAndDelete =
      (data?.deletePage && emailTemplate?.length >= 1) ||
      (filterData.isFilter &&
        filterData.category &&
        data?.statusChange &&
        emailTemplate?.length >= 1)

    if (shouldUpdatePaginationAndDelete) {
      if (emailTemplate?.length >= 1) {
        setPageAndDelete()
      }
    } else {
      setIsDelete(false)
    }
  }

  const setPageAndDelete = () => {
    setPage(page - 1)
    setIsDelete(true)
    setPaginationObj({ ...paginationObj, page: page - 1 })
  }

  const buildPayload = () => ({
    page,
    limit: pageSize,
    sortKey: sort.sortKey,
    sortType: sort.sortType,
    keyword: filterData.searchKey?.trim(),
    status: filterData.category
  })

  const handleApiCall = async () => {
    const payload = buildPayload()
    const path = apiPath.emailTemplate

    try {
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        handleApiResult(result.data)
      }
    } catch (error) {
      handleApiError(error)
    }
  }

  const handleApiResult = data => {
    const response = data.results
    const resultStatus = data.success

    setEmailTemplate(response?.docs)
    setPaginationObj({
      ...paginationObj,
      page: resultStatus ? response.page : null,
      pageCount: resultStatus ? response.totalPages : null,
      perPageItem: resultStatus ? response?.docs.length : null,
      totalItems: resultStatus ? response.totalDocs : null
    })
  }

  const handleApiError = error => {
    console.error('error ', error)
    setPaginationObj({})

    if (error.response?.status === 401 || error.response?.status === 409) {
      logoutUser()
    }
  }

  const getEmailTemplate = async data => {
    try {
      handlePaginationAndDelete()
      await handleApiCall()
    } catch (error) {
      console.error('Unhandled error: ', error)
      setPaginationObj({})
    }
  }

  const handlePageClick = event => {
    const newPage = event.selected + 1
    setPage(newPage)
  }

  useEffect(() => {
    getEmailTemplate()
  }, [page, pageSize, filterData])

  const pageSizeCall = value => {
    setPageSize(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm?.trim())
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchTerm])

  useEffect(() => {
    helpers.setDebouncingFunction({
      isInitialized,
      setIsInitialized,
      debouncedSearchTerm,
      searchTerm,
      filterData,
      setFilterData,
      setPage
    })
  }, [debouncedSearchTerm])

  return (
    <div>
      <div className='bg-[#F9F9F9] dark:bg-LightBlue'>
        <div className='px-3 py-4'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg dark:bg-gray-800 '>
            <form className='border-b border-b-[#E3E3E3]  px-4 py-3 pt-5 flex flex-wrap justify-between'>
              <div className='flex flex-wrap  items-center'>
                <div className='flex items-center lg:pt-0 pt-3 justify-center'>
                  <div className='flex items-center mb-3 ml-3'>
                    <select
                      className='block p-2 w-full text-sm text-[#A5A5A5] bg-transparent border-2 dark:border-[#fff] rounded-lg border-[#DFDFDF]  dark:text-[#A5A5A5] dark:border-gray-600'
                      id='countries'
                      name='floating_password'
                      type=' password'
                      placeholder=' '
                      onChange={e => statusPage(e)}
                      value={filterData?.category}
                    >
                      <option defaultValue value=''>
                        {t('O_ALL')}
                      </option>
                      <option value='active'>{t('O_ACTIVE')}</option>
                      <option value='inactive'>{t('O_INACTIVE')}</option>
                    </select>
                  </div>
                  <button
                    onClick={() => handleReset()}
                    title={t('O_RESET')}
                    type='button'
                    className='bg-LightBlue text-sm px-8 ml-3 mb-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue sm:w-auto w-1/2'
                  >
                    {t('O_RESET')}
                  </button>
                </div>
              </div>
              <div className='flex items-center md:justify-end px-4'>
                <label
                  className='mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white'
                  htmlFor='default-search'
                >
                  {t('O_SEARCH')}
                </label>
                <div className='flex'>
                  <div className='relative '>
                    <div className='absolute inset-y-0 right-0 flex items-center pl-3 mr-3 pointer-events-none'>
                      {!searchTerm ? (
                        <svg
                          aria-hidden='true'
                          className='w-4 h-4 text-[#A5A5A5] dark:text-gray-40'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth='2'
                            d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                          />
                        </svg>
                      ) : (
                        ''
                      )}
                    </div>
                    <input
                      type='search'
                      id='default-search'
                      className='block w-full p-2 outline-none text-sm text-gray-900 2xl:min-w-[250px] xl:min-w-[300px] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                      placeholder='Template title'
                      value={searchTerm}
                      title=''
                      required
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </form>
            <Table
              emailTemplate={emailTemplate}
              user={user}
              getEmailTemplate={getEmailTemplate}
              page={page}
              setSort={setSort}
              sort={sort}
              manager={manager}
            />
            {paginationObj?.totalItems ? (
              <Pagination
                handlePageClick={handlePageClick}
                options={paginationObj}
                isDelete={isDelete}
                page={page}
                setPageSize={pageSizeCall}
                pageSize={pageSize}
              />
            ) : null}
          </div>
        </div>
      </div>
      <></>
    </div>
  )
}
export default EmailTemplate
