import React from 'react'
import { useTranslation } from 'react-i18next'

const LogsView = ({ item, handleView }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 overflow-y-auto outline-none focus:outline-none'>
        <div className='relative w-auto my-6 mx-auto max-w-3xl'>
          <div className='md:py-4 sm:px-2 sm:py-8 px-7'>
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none min-w-[925px]'>
              <div className='dark:text-white dark:bg-LightBlue border-b border-solid border-slate-200 rounded-t flex items-center justify-between p-5 '>
                <h3 className='font-semibold text-xl'>{t('VIEW_LOGS')}</h3>
                <button
                  onClick={() => handleView(false)}
                  className=' ml-auto flex items-center justify-center text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                >
                  <span
                    onClick={() => handleView()}
                    className=' text-[#B8BBBF]  text-4xl '
                    title='Close'
                  >
                    ×
                  </span>
                </button>
              </div>
              <div className='relative p-6 flex-auto dark:bg-gray-800'>
                <div className='grid'>
                  <div className='sm:p-4 p-8  bg-slate-200 rounded-md mb-3 dark:bg-gray-800 dark:text-white'>
                    <div className='relative z-0 mb-1 w-full group break-all max-h-[340px] overflow-y-auto'>
                      <strong>Logs:</strong>
                      <h6>{item.text || ''}</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className='dark:bg-LightBlue flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  title={t('O_BACK')}
                  className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => handleView()}
                >
                  {t('O_BACK')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default LogsView
