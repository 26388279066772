import React, { useContext } from 'react'
import Sidebar from '../components/Sidebar'
import TopNavBar from '../components/TopNavBar'
import Loader from './Loader'
import Toast from './Toast'
import AuthContext from 'context/AuthContext'

function MainLayout ({ children }) {
  const { sideBarOpen } = useContext(AuthContext)
  return (
    <div className='main_wrap'>
      <Loader />
      <Toast />
      {sideBarOpen && <Sidebar />}
      <div
        className={`${
          sideBarOpen ? 'right_panel xl:ml-[280px] lg:ml-[220px] ml-0 dark:bg-LightBlue h-screen' : ''
        }`}
      >
        <TopNavBar />
        {children}
      </div>
    </div>
  )
}

export default MainLayout
