import React from 'react'
import UserActivityList from './UserActivityList'

const UserActivityReports = ({ manager }) => {
  return (
    <>
      {' '}
      <div className='bg-[#F9F9F9]'>
        <div className='bg-white rounded-lg border border-[#E9EDF9]'>
          <UserActivityList manager={manager} />
        </div>
      </div>
    </>
  )
}

export default UserActivityReports
