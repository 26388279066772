import React, { createContext, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { pick } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { apiPost, apiGet } from '../utils/apiFetch'
import apiPath from '../utils/pathObj'
import useToastContext from 'hooks/useToastContext'

const AuthContext = createContext()

export default AuthContext

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(() =>
    localStorage.getItem('token')
      ? jwt_decode(localStorage.getItem('token'))
      : null
  )
  const [activeUser, setActiveUser] = useState('')
  const [sideBarOpen, setSideBarOpen] = useState(true)
  const [sidebarStatus, setSidebarStatus] = useState(
    localStorage.getItem('sidebar')
  )
  const [pageName, setPageName] = useState(
    localStorage.getItem('pageName')
      ? localStorage.getItem('pageName')
      : 'Dashboard'
  )
  const [profileData, setProfileData] = useState('')
  const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0))
    const byteHex = n => ('0' + Number(n).toString(16)).substr(-2)
    const applySaltToChar = code =>
      textToChars(salt).reduce((a, b) => a ^ b, code)

    return text =>
      text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('')
  }
  const myCipher = cipher('mySecretSalt')
  const navigate = useNavigate()
  const notification = useToastContext()
  const [loginMessage, setLoginMessage] = useState(
    'Admin details updated successfully.'
  )

  const updatePageName = name => {
    localStorage.setItem('pageName', name)
    setPageName(name)
  }

  let loginUser = async body => {
    document.getElementById('loader').classList.remove('hidden')
    const { status, data } = await apiPost(
      apiPath.loginUser,
      pick(body, ['email', 'password'])
    )

    if (status === 200) {
      if (data.success) {
        if (
          data?.results.role === 'subadmin' &&
          !data?.results?.isPasswordSet
        ) {
          const token = data?.results?.token || null
          const refresh_token = data?.results?.refresh_token || null
          localStorage.setItem('token', token)
          localStorage.setItem('refresh_token', refresh_token)
          setUser(jwt_decode(token))
          navigate('/change-password')
        } else {
          const token = data?.results?.token || null
          const refresh_token = data?.results?.refresh_token || null
          localStorage.setItem('token', token)
          localStorage.setItem('refresh_token', refresh_token)
          localStorage.setItem('pageName', 'Dashboard')
          setPageName('Dashboard')
          setUser(jwt_decode(token))
          window.localStorage.setItem('pass', myCipher(body.password))
          notification.success(data.message)
          navigate('/dashboard')
        }
      } else {
        notification.error(data.message)
      }
    }
    document.getElementById('loader').classList.add('hidden')
  }

  const getProfileList = async () => {
    try {
      const res = await apiGet(apiPath.getProfileList, {})
      setProfileData(res?.data?.results)
    } catch (error) {
      console.log('error:', error)
    }
  }

  const updateProfile = async formData => {
    try {
      const res = await apiPost(apiPath.editProfile, formData)
      const token = res?.data?.results?.token || null
      const refresh_token = res?.data?.results?.refresh_token || null
      localStorage.setItem('token', token)
      setLoginMessage(res?.data?.message)
      notification.success(res?.data?.message)
      getProfileList()
      localStorage.setItem('refresh_token', refresh_token)
      setUser(jwt_decode(token))
    } catch (err) {
      console.error('err:', err)
    }
  }

  const handleSidebar = () => {
    let s1 = localStorage.getItem('sidebar')
    localStorage.setItem('sidebar', s1 == 'open' ? 'close' : 'open')
    setSidebarStatus(s1 == 'open' ? 'close' : 'open')
  }

  let logoutUser = () => {
    setUser(null)
    localStorage.removeItem('token')
    localStorage.removeItem('refresh_token')
    notification.success('Logout successfully.')
    navigate('/login')
  }

  let contextData = {
    user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    updateProfile: updateProfile,
    sidebarStatus: sidebarStatus,
    handleSidebar,
    pageName,
    updatePageName,
    setSideBarOpen,
    sideBarOpen,
    loginMessage: loginMessage,
    setActiveUser,
    setProfileData,
    profileData,
    getProfileList,
    activeUser
  }
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  )
}
