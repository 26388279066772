import ErrorMessage from 'components/ErrorMessage'
import { useForm } from 'react-hook-form'
import { apiPost } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import { preventMaxInput } from 'utils/validations'

const AddFAQ = ({ setShowModal, getAllFAQ }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onChange', shouldFocusError: true, defaultValues: {} })

  const notification = useToastContext()
  const handleSubmitForm = async data => {
    try {
      data.title = data?.title?.trim()
      const path = apiPath.getFAQs
      const result = await apiPost(path, data)
      if (result?.data?.success === true) {
        notification.success(result?.data?.message)
        getAllFAQ()
        setShowModal(false)
      } else {
        notification.error(result?.data?.message)
      }
    } catch (error) {
      console.log('error:', error.message)
    }
  }

  return (
    <>
      <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
        <form onSubmit={handleSubmit(handleSubmitForm)} method='post'>
          <div className='relative w-auto my-6 mx-auto max-w-3xl'>
            <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none min-w-[762px]'>
              <div className='dark:bg-LightBlue dark:text-white flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h3 className='text-xl font-semibold'>{t('FAQS_ADD_FAQS')}</h3>
                <button
                  className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                  onClick={() => setShowModal(false)}
                >
                  <a
                    title='Close'
                    className='hover:text-blue-700 transition duration-150 ease-in-out'
                    data-bs-toggle='tooltip'
                  >
                    {' '}
                    <span className=' text-[#B8BBBF]  text-4xl '>×</span>
                  </a>
                </button>
              </div>
              <div className='relative p-6 flex-auto dark:bg-gray-800'>
                <div className='grid grid-cols-1'>
                  <div className='w-full'>
                    <div
                      onSubmit={handleSubmit(handleSubmitForm)}
                      method='post'
                      className='w-full'
                    >
                      <div className='relative z-0 mb-6 w-full '>
                        <input
                          type='text'
                          name='title'
                          id='title'
                          autoFocus
                          className=' py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          maxLength={300}
                          onInput={e => preventMaxInput(e, 300)}
                          {...register('title', {
                            required: 'Please enter title.',
                            minLength: {
                              value: 2,
                              message: 'Minimum length must be 2.'
                            }
                          })}
                        />
                        <label
                          className='dark:text-gray-400 duration-300 peer-focus:font-normal absolute text-sm text-[#A5A5A5] transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                          for='title'
                          maxLength={100}
                        >
                          {t('FAQS_TITLE')}
                          <span className='text-red-500'>*</span>
                        </label>
                        <ErrorMessage message={errors?.title?.message} />
                      </div>
                      <div className='relative z-0 mb-6 w-full group'>
                        <textarea
                          type='textarea'
                          name='content'
                          id='content'
                          onInput={e => preventMaxInput(e, 400)}
                          className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
                          placeholder=' '
                          {...register('content', {
                            required: 'Please enter content.',
                            minLength: {
                              value: 10,
                              message: 'Minimum length must be 10.'
                            },
                            maxLength: {
                              value: 400,
                              message: 'Maximum character can be 200.'
                            }
                          })}
                        />
                        <label
                          for='content'
                          className='dark:bg-gray-800 peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
                        >
                          {t('FAQS_CONTENT')}
                          <span className='text-red-500'>*</span>
                        </label>
                        <ErrorMessage message={errors?.content?.message} />
                      </div>
                    </div>
                  </div>
                  <div />
                </div>
              </div>
              <div className='dark:bg-LightBlue flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                <button
                  title={t('O_BACK')}
                  className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                  type='button'
                  onClick={() => setShowModal(false)}
                >
                  {t('O_BACK')}
                </button>
                <button
                  title={t('O_SAVE')}
                  className='dark:bg-gray-800 bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                  type='submit'
                >
                  {t('O_SAVE')}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='opacity-25 fixed inset-0 z-40 bg-black' />
    </>
  )
}

export default AddFAQ
