import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import AuthContext from 'context/AuthContext'
import TransactionHistory from './transactionHistory/TransactionHistory'
import UserActivityReports from './userActivityReports/UserActivityReports'

const tabBtnClass =
  'inline-block py-3 px-8 text-md xl:text-lg font-semibold border-b-2'
const tabActiveClass = 'border-blue-600 text-blue-600'
const tabBorderNoneClass = 'border-transparent'
const TAB_TRANSACTION_HISTORY = 'transactionHistory'
const TAB_USER_ACTIVITY_REPORTS = 'userActivityReports'
const ReportsManager = () => {
  const { t } = useTranslation()
  const { updatePageName, user } = useContext(AuthContext)
  const manager =
    user?.permission?.find(
      e => e.manager === 'reports-and--analytics-manager'
    ) ?? {}
  const [defaultTab, setDefaultTab] = useState('transactionHistory')
  const handleTabClick = (action, e) => {
    setDefaultTab(action)
  }

  useEffect(() => {
    updatePageName(t('NAV_REPORTS_MANAGER'))
  }, [])
  return (
    <div className='py-5 px-9'>
      <div className='py-3 my-2'>
        <ul className='border-b flex'>
          <li className='mr-0 xl:mr-5'>
            <button
              onClick={handleTabClick.bind(this, TAB_TRANSACTION_HISTORY)}
              className={classNames({
                [tabBtnClass]: true,
                [tabActiveClass]: defaultTab === TAB_TRANSACTION_HISTORY,
                [tabBorderNoneClass]: defaultTab !== TAB_TRANSACTION_HISTORY
              })}
            >
              {t('TRANSACTION_HISTORY')}
            </button>
          </li>
          <li className='mx-0 xl:mx-5'>
            <button
              onClick={handleTabClick.bind(this, TAB_USER_ACTIVITY_REPORTS)}
              className={classNames({
                [tabBtnClass]: true,
                [tabActiveClass]: defaultTab === TAB_USER_ACTIVITY_REPORTS,
                [tabBorderNoneClass]: defaultTab !== TAB_USER_ACTIVITY_REPORTS
              })}
            >
              {t('USER_ACTIVITY_REPORTS')}
            </button>
          </li>
        </ul>
      </div>

      {defaultTab === TAB_TRANSACTION_HISTORY ? (
        <TransactionHistory manager={manager} />
      ) : (
        defaultTab === TAB_USER_ACTIVITY_REPORTS && (
          <UserActivityReports manager={manager} />
        )
      )}
    </div>
  )
}

export default ReportsManager
