import './App.css'
import './assets/css/style.css'
import 'react-quill/dist/quill.snow.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { AuthProvider } from './context/AuthContext'
import { ToastContextProvider } from './context/ToastContext'
import AuthorizationRoute from './utils/AuthorizationRoute'
import Login from './pages/Login'
import Home from './pages/Home'
import User from './pages/users/User'
import SubAdmin from './pages/sub_admin/SubAdmin'
import TransactionManager from './pages/transaction_manager/TransactionManager'
import Settings from './pages/settings/Settings'
import AboutUs from './pages/static_content/AboutUs'
import ContactUs from './pages/static_content/ContactUs'
import PrivatePolicy from './pages/static_content/PrivatePolicy'
import TermsAndConditions from './pages/static_content/TermsAndConditions'
import Profile from 'pages/profile/Profile'
import ForgotPassword from 'pages/auth/ForgotPassword'
import ResetPassword from 'pages/auth/ResetPassword'
import ChangePassword from 'pages/auth/ChangePassword'
import Reports from 'pages/reports/Reports'
import SharedLayout from 'utils/SharedLayout'
import ProtectedRoute from 'utils/ProtectedRoute'
import NotFound from 'pages/NotFound'
import EmailTemplate from 'pages/email_template/EmailTemplate'
import AddEditEmailTemplate from 'pages/email_template/AddEditEmailTemplate'
import Notification from 'pages/notification/Notification'
import StaticContent from 'pages/static_content/StaticContent'
import FAQ from 'pages/faqs/FAQ'
import SupportManager from 'pages/support_manager/SupportManager'
import SubAdd from 'pages/sub_admin/SubAdd'
import SubEdit from 'pages/sub_admin/SubEdit'
import UserView from 'pages/users/UserView'
import LogsManager from 'pages/logs_manager/LogsManager'
import ReplyViewMessage from 'pages/support_manager/ReplyViewMessage'
import NotificationManager from 'pages/notification_manager/NotificationManager'
import NotificationAdd from 'pages/notification_manager/NotificationAdd'
import ReportsManager from 'pages/reports_manager/ReportsManager'

function App () {
  return (
    <BrowserRouter>
      <ToastContextProvider>
        <AuthProvider>
          <Routes>
            <Route element={<SharedLayout />}>
              {/* <Route
                exact
                path="/"
                element={
                  <ProtectedRoute>
                  </ProtectedRoute>
                }
              ></Route> */}

              <Route exact path='/dashboard' element={<Home />} />

              <Route
                exact
                path='/user-manager'
                element={
                  <AuthorizationRoute>
                    <User />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/user-manager/view'
                element={
                  <AuthorizationRoute>
                    <UserView />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/transaction-manager'
                element={
                  <AuthorizationRoute>
                    <TransactionManager />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/reports-manager'
                element={
                  <AuthorizationRoute>
                    <ReportsManager />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/sub-admin-manager'
                element={
                  <AuthorizationRoute>
                    <SubAdmin />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/sub-admin-manager/add'
                element={
                  <AuthorizationRoute>
                    <SubAdd />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/sub-admin-manager/edit'
                element={
                  <AuthorizationRoute>
                    <SubEdit />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/support-manager'
                element={
                  <AuthorizationRoute>
                    <SupportManager />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/support-manager/view'
                element={
                  <AuthorizationRoute>
                    <ReplyViewMessage />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/faqs'
                element={
                  <AuthorizationRoute>
                    <FAQ />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/logs'
                element={
                  <AuthorizationRoute>
                    <LogsManager />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/manage-static-contents'
                element={
                  <AuthorizationRoute>
                    <StaticContent />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/static-about-us'
                element={
                  <AuthorizationRoute>
                    <AboutUs />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/static-contact-us'
                element={
                  <AuthorizationRoute>
                    <ContactUs />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/static-terms-and-conditions'
                element={
                  <AuthorizationRoute>
                    <TermsAndConditions />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/static-privacy-policy'
                element={
                  <AuthorizationRoute>
                    <PrivatePolicy />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/global-setting'
                element={
                  <AuthorizationRoute>
                    <Settings />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/transaction-manager'
                element={
                  <AuthorizationRoute>
                    <TransactionManager />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/notification-manager'
                element={
                  <AuthorizationRoute>
                    <NotificationManager />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/notification-manager/add'
                element={
                  <AuthorizationRoute>
                    <NotificationAdd />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/profile'
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />

              <Route
                exact
                path='/emailTemplate'
                element={
                  <AuthorizationRoute>
                    <EmailTemplate />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/emailTemplate/edit'
                element={
                  <ProtectedRoute>
                    <AddEditEmailTemplate />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path='/emailTemplate/add'
                element={
                  <ProtectedRoute>
                    <AddEditEmailTemplate />
                  </ProtectedRoute>
                }
              />

              <Route
                exact
                path='/reports'
                element={
                  <AuthorizationRoute>
                    <Reports />
                  </AuthorizationRoute>
                }
              />
              <Route
                exact
                path='/notifications'
                element={
                  <AuthorizationRoute>
                    <Notification />
                  </AuthorizationRoute>
                }
              />

              <Route
                exact
                path='/change-password'
                element={
                  <ProtectedRoute>
                    <ChangePassword />
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route exact path='/' element={<Login />} />
            <Route exact path='/login' element={<Login />} />

            <Route exact path='/forgot-password' element={<ForgotPassword />} />

            <Route
              exact
              path='/reset-password/:resetToken'
              element={<ResetPassword />}
            />
            <Route exact path='*' element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </ToastContextProvider>
    </BrowserRouter>
  )
}

export default App
