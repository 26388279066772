import { useTranslation } from 'react-i18next'
import { isEmpty, startCase } from 'lodash'

const ViewRemittanceTransactionList = ({ page, remittanceTransactions }) => {
  const { t } = useTranslation()

  return (
    <div className='p-3'>
      <div className='relative rounded-lg overflow-x-auto  border'>
        <table className='text-[#6c6c6c] dark:text-gray-400 w-full text-xs text-left'>
          <thead className='text-xs text-gray-900 border dark:bg-gray-700 border-[#E1E6EE] bg-[#E1E6EE] dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='py-3 px-6'>
                {t('SENDER_NAME')}
              </th>
              <th scope='col' className='px-6 py-3 '>
                {t('BANK_NAME')}
              </th>
              <th className='py-3 px-6' scope='col'>
                {t('ACCOUNT_NUMBER')}
              </th>
              <th scope='col' className='px-6 py-3'>
                {t('BANK_CODE')}
              </th>
              <th scope='col' className='px-6 py-3'>
                {t('O_MOBILE')}
              </th>
              <th className='py-3 px-6' scope='col'>
                {t('AMOUNT')}
              </th>
              <th scope='col' className='px-6 py-3'>
                {t('COUNTRY_AND_CURRENCY')}
              </th>
              <th className='py-3 px-6' scope='col'>
                {t('O_STATUS')}
              </th>
            </tr>
          </thead>

          <tbody>
            {remittanceTransactions &&
              remittanceTransactions.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                  >
                    <th
                      scope='row'
                      className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                    >
                      {i + 1 + 10 * (page - 1)}
                    </th>
                    <td className='py-4 border-r text-gray-900 px-6'>
                      {item?.sender?.fullName}
                    </td>
                    <td className='py-4 text-gray-900 px-6 border-r'>
                      {item?.bankName || '-'}
                    </td>
                    <td className='text-gray-900 py-4 border-r px-6'>
                      {item?.accountNumber || '-'}
                    </td>
                    <td className='py-4 px-6 border-r text-gray-900'>
                      {item?.bankCode || '-'}
                    </td>
                    <td className='py-4 text-gray-900 px-6 border-r'>
                      {item?.mobile || '-'}
                    </td>
                    <td className='text-gray-900 py-4 px-6 border-r text-right'>
                      {item?.amount?.toFixed(2)}
                    </td>
                    <td className='py-4 px-6 text-gray-900 border-r'>{`${item?.country} (${item?.currency})`}</td>
                    <td className='py-4 text-gray-900 border-r px-6'>
                      {startCase(item?.status)}
                    </td>
                  </tr>
                )
              })}
            {isEmpty(remittanceTransactions) ? (
              <tr className='bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6 border-r' colSpan={9}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ViewRemittanceTransactionList
