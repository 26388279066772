import useToastContext from 'hooks/useToastContext'
import React, { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { apiPost } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import Permission from './Permission'
import OInputField from 'components/reusable/OInputField'
import { preventMaxInput } from 'utils/validations'
import formValidation from '../../utils/formValidation'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import AuthContext from 'context/AuthContext'
const { startCase, capitalize } = require('lodash')

const SubAdd = ({ setShowModal, allSubAdmin, setPage }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true
  })

  const notification = useToastContext()
  const [permissionJons, setPermission] = useState(Permission)
  const { updatePageName } = useContext(AuthContext)

  const [addMerchantLoading, setAddMerchantLoading] = useState(false)

  const onChange = event => {
    setPermission(current =>
      current.map(obj => {
        if (obj.manager === event.target.name) {
          if (
            (event.target.id === 'add' || event.target.id === 'edit') &&
            event.target.checked
          ) {
            return {
              ...obj,
              [event.target.id]: event.target.checked,
              view: event.target.checked
            }
          } else if (event.target.id === 'view' && !event.target.checked) {
            return {
              ...obj,
              add: false,
              edit: false,
              view: false
            }
          }
          return { ...obj, [event.target.id]: event.target.checked }
        }
        return obj
      })
    )
  }

  const onSubmit = async data => {
    setAddMerchantLoading(true)
    try {
      const path = apiPath.addSubAdmin
      const myObj = {
        manager: 'dashboard',
        add: true,
        edit: true,
        view: true
      }
      const permission = JSON.stringify([myObj, ...permissionJons])
      data.email = data?.email?.trim()
      const sendData = { ...data, permission }
      const result = await apiPost(path, sendData)

      if (result?.data?.success) {
        navigate('/sub-admin-manager')
        allSubAdmin()
        setPage(1)
        notification.success(result.data.message)
      } else {
        notification.error(result.data.message)
      }
    } catch (error) {
      console.error('error in get all sub admin list==>>>>', error.message)
    }

    setAddMerchantLoading(false)
  }

  const renderInputField = (name, label, maxLength, autoFocus, validation) => (
    <OInputField
      wrapperClassName='relative z-0 mb-3 w-full group'
      name={name}
      inputLabel={
        <>
          {label}
          <span className='text-red-500'>*</span>
        </>
      }
      type='text'
      autoFocus={autoFocus}
      maxLength={maxLength}
      onInput={e => preventMaxInput(e, maxLength)}
      register={register(name, validation)}
      errors={errors}
    />
  )

  const renderCheckbox = (name, id, checked) => (
    <input
      type='checkbox'
      name={name}
      id={id}
      checked={checked}
      onChange={onChange}
    />
  )

  const renderFooter = () => (
    <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
      <button
        title={t('O_BACK')}
        className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
        type='button'
        onClick={() => navigate('/sub-admin-manager')}
      >
        {t('O_BACK')}
      </button>
      {addMerchantLoading ? (
        <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
          <div className='loading-spinner'></div>
        </div>
      ) : (
        <button
          title={t('O_SAVE')}
          className='dark:bg-gray-800 bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
          type='submit'
          onClick={handleSubmit(onSubmit)}
        >
          {t('O_SAVE')}
        </button>
      )}
    </div>
  )

  useEffect(() => {
    updatePageName(t('SUB_ADMIN_ADD_SUB_ADMIN'))
  }, [])

  return (
    <>
      <div className='relative p-6 flex-auto dark:bg-LightBlue'>
        <div className='grid lg:grid-cols-4 dark:bg-gray-800 py-3 rounded-lg mb-4'>
          <div className='px-2'>
            {renderInputField(
              'firstName',
              t('O_FIRST_NAME'),
              15,
              true,
              formValidation['firstNames']
            )}
          </div>

          <div className='px-2'>
            {renderInputField(
              'lastName',
              t('O_LAST_NAME'),
              15,
              false,
              formValidation['lastName']
            )}
          </div>
          <div className='px-2'>
            {renderInputField(
              'email',
              t('EMAIL_ADDRESS'),
              50,
              false,
              formValidation['emailAddress']
            )}
          </div>
        </div>
        <div className='pl-3 pr-3'>
          <div className='overflow-y-auto relative rounded-lg border overflow-x-auto'>
            <table className='text-left text-[#A5A5A5] dark:text-gray-400 w-full text-xs'>
              <thead className='text-xs text-gray-900 border dark:bg-gray-700 dark:text-gray-400 border-[#E1E6EE] bg-[#E1E6EE]'>
                <tr>
                  <th className='py-3 px-6 ' scope='col'>
                    {t('SUB_ADMIN_MANAGER')}
                  </th>
                  <th scope='col' className='py-3 px-6 '>
                    {t('O_VIEW')}
                  </th>
                  <th scope='col' className='py-3 px-6 '>
                    {t('SUB_ADMIN_ADD')}
                  </th>
                  <th scope='col' className='py-3 px-6 '>
                    {t('SUB_ADMIN_EDIT')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {permissionJons?.map((item, i) => (
                  <tr
                    key={i}
                    className='bg-white border-b  dark:bg-gray-800 dark:border-gray-700'
                  >
                    <td className='py-4 px-6 border-r '>
                      {capitalize(startCase(item.manager))}
                    </td>
                    <td className='py-4 px-6 border-r '>
                      {item?.showView && (
                        <>{renderCheckbox(item.manager, 'view', item.view)}</>
                      )}
                    </td>
                    <td className='py-4 px-6 border-r '>
                      {item?.showAdd && (
                        <>{renderCheckbox(item.manager, 'add', item.add)}</>
                      )}
                    </td>
                    <td className='py-4 px-6 border-r '>
                      {item?.showEdit && (
                        <>{renderCheckbox(item.manager, 'edit', item.edit)}</>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {renderFooter()}
    </>
  )
}

export default SubAdd
