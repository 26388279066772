import React, { useState } from 'react'
import { apiPost, apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm, Controller } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import OInputField from 'components/reusable/OInputField'
import { preventMaxInput } from 'utils/validations'
import formValidation from '../../utils/formValidation'
import OButton from 'components/reusable/OButton'
import { Link } from 'react-router-dom'
import ReactQuill from 'react-quill'
import { useLocation, useNavigate } from 'react-router'
import ErrorMessage from 'components/ErrorMessage'

export default function AddEditEmailTemplate () {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const editItem = location?.state?.item
  const notification = useToastContext()
  const [isLoading, setIsLoading] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      title: editItem?.title,
      subject: editItem?.subject
    }
  })

  const handleSubmitForm = async data => {
    try {
      setIsLoading(true)
      let path
      let result
      const paylod = { ...data }
      if (location?.pathname === '/emailTemplate/add') {
        path = apiPath.emailTemplate
        result = await apiPost(path, paylod)
      } else if (location?.pathname === '/emailTemplate/edit') {
        path = apiPath.emailTemplate + '/' + editItem._id
        result = await apiPut(path, paylod)
      }

      if (result.data.success) {
        notification.success(result?.data.message)
        navigate('/emailTemplate')
      } else {
        notification.error(result?.data.message)
      }
    } catch (error) {
      console.error('error in get all users list==>>>>', error)
      notification.error(error.message)
    }
    setIsLoading(false)
  }

  const renderButtonLabel = () => {
    return location?.pathname === '/emailTemplate/edit' ? t('O_SAVE') : 'Add'
  }

  const renderButton = () => {
    if (isLoading) {
      return (
        <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 ease-linear transition-all duration-150'>
          <div className='loading-spinner' />
        </div>
      )
    } else {
      return (
        <OButton
          title={t('O_EDIT')}
          label={renderButtonLabel()}
          type='submit'
          loading={isLoading}
        />
      )
    }
  }

  const QuillEditor = ({
    name,
    defaultValue,
    label,
    controls,
    rules,
    error
  }) => (
    <div className='md:py-4 sm:px-2 sm:py-3 md:px-7 px-2'>
      <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
        {label}
      </label>
      <Controller
        name={name}
        control={controls}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <ReactQuill
            modules={{
              toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' }
                ],
                ['link', 'image', 'video'],
                ['clean']
              ]
            }}
            theme='snow'
            placeholder='Write something...'
            {...field}
          />
        )}
      />
      <ErrorMessage message={error?.[name]?.message} />
    </div>
  )

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitForm)} method='post'>
        <div className='outer-boarder dark:bg-gray-800'>
          <div className='headerForm '>
            {editItem ? t('O_EDIT') : 'Add'} email template
          </div>
          <div className='relative p-6  pb-0 flex-auto'>
            <div className='grid grid-cols-2'>
              <div className='md:py-4 sm:py-3 px-2'>
                <OInputField
                  wrapperClassName='relative z-0  w-full group'
                  name='title'
                  inputLabel={
                    <>
                      Template title<span className='text-red-500'>*</span>
                    </>
                  }
                  type='text'
                  autoFocus
                  maxLength={25}
                  onInput={e => preventMaxInput(e, 25)}
                  register={register('title', formValidation.title)}
                  errors={errors}
                />
              </div>
              <div className='md:py-4 sm:py-3 px-2 '>
                <OInputField
                  wrapperClassName='relative z-0   w-full group'
                  name='subject'
                  inputLabel={
                    <>
                      Subject<span className='text-red-500'>*</span>
                    </>
                  }
                  type='text'
                  maxLength={500}
                  onInput={e => preventMaxInput(e, 500)}
                  register={register('subject', formValidation.subject)}
                  errors={errors}
                />
              </div>
            </div>
          </div>

          <QuillEditor
            name='description'
            controls={control}
            defaultValue={editItem?.description}
            label='Content'
            rules={{ required: 'Content is required' }}
            errors={errors}
            className="dark:text-white"
          />
          <ErrorMessage message={errors?.description?.message} />

          <QuillEditor
            name='keyword'
            controls={control}
            defaultValue={editItem?.keyword}
            label='Keyword'
            errors={errors}
            className="dark:text-white"
          />

          <div className='flex items-center justify-center p-3 mt-3 border bg-[#cbd5e13a]  rounded-b'>
            <Link to='/emailTemplate'>
              <button
                title={t('O_BACK')}
                className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                type='button'
              >
                {t('O_BACK')}
              </button>
            </Link>

            {renderButton()}
          </div>
        </div>
      </form>
    </>
  )
}
