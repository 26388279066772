import React, { useState, useEffect, useContext } from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useForm } from 'react-hook-form'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import OInputField from 'components/reusable/OInputField'
import { preventMaxInput } from 'utils/validations'
import formValidation from '../../utils/formValidation'
import { useLocation, useNavigate } from 'react-router'
import AuthContext from 'context/AuthContext'
const { startCase, capitalize } = require('lodash')

const SubEdit = ({ setEditShowModal, allSubAdmin }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const item = location.state
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      email: item?.email,
      firstName: item?.firstName,
      lastName: item?.lastName,
      permission: item?.permission
    }
  })

  const notification = useToastContext()
  const { updatePageName } = useContext(AuthContext)
  const [permissionJson, setPermissionJson] = useState(getValues('permission'))
  const [isLoading, setIsLoading] = useState(false)
  const onChange = event => {
    setPermissionJson(current =>
      current.map(obj => {
        if (obj.manager === event.target.name) {
          if (
            (event.target.id === 'add' || event.target.id === 'edit') &&
            event.target.checked
          ) {
            return {
              ...obj,
              [event.target.id]: event.target.checked,
              view: event.target.checked
            }
          } else if (event.target.id === 'view' && !event.target.checked) {
            return {
              ...obj,
              add: false,
              edit: false,
              view: false
            }
          }
          return { ...obj, [event.target.id]: event.target.checked }
        }
        return obj
      })
    )
  }

  const onSubmit = async data => {
    try {
      setIsLoading(true)
      const path = apiPath.editSubAdmin + '/' + item._id
      const myObj = {
        manager: 'dashboard',
        add: true,
        view: true,
        edit: true
      }
      const permission = JSON.stringify([myObj, ...permissionJson])
      data.email = data?.email?.trim()
      const sendData = { ...data, permission }
      const result = await apiPut(path, sendData)
      if (result?.data?.success) {
        navigate('/sub-admin-manager')
        allSubAdmin()
        notification.success(result.data.message)
      } else {
        notification.error(result.data.message)
      }
    } catch (error) {
      console.error('error in get all users list==>>>>', error.message)
    }
    setIsLoading(false)
  }

  const renderInputField = (autoFocus, name, label, maxLength, validation) => (
    <OInputField
      wrapperClassName='relative z-0 mb-6 w-full group'
      name={name}
      inputLabel={
        <>
          {label}
          <span className='text-red-500'>*</span>
        </>
      }
      type='text'
      autoFocus={autoFocus}
      maxLength={maxLength}
      onInput={e => preventMaxInput(e, maxLength)}
      register={register(name, validation)}
      errors={errors}
    />
  )

  useEffect(() => {
    const permissionData = getValues('permission')
    console.log('permissionData', permissionData, item)
    if (permissionData && permissionData[0]?.manager === 'dashboard') {
      permissionData.shift()
    }
    setPermissionJson(permissionData)
  }, [])

  const renderFooter = () => (
    <div className='flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
      <button
        title={t('O_BACK')}
        className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
        type='button'
        onClick={() => navigate('/sub-admin-manager')}
      >
        {t('O_BACK')}
      </button>
      {isLoading ? (
        <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
          <div className='loading-spinner'></div>
        </div>
      ) : (
        <button
          title={t('O_SAVE')}
          className='dark:bg-gray-800 bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
          type='submit'
          onClick={handleSubmit(onSubmit)}
        >
          {t('O_SAVE')}
        </button>
      )}
    </div>
  )

  useEffect(() => {
    updatePageName(t('EDIT_SUB_ADMIN'))
  }, [])

  return (
    <>
      <div className='relative p-6 flex-auto'>
        <div className='grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 dark:bg-gray-800 pt-6 px-2 mb-5 rounded-md'>
          <div className='px-2'>
            {renderInputField(
              true,
              'firstName',
              t('O_FIRST_NAME'),
              15,
              formValidation['firstNames']
            )}
          </div>
          <div className='px-2'>
            {renderInputField(
              false,
              'lastName',
              t('O_LAST_NAME'),
              15,
              formValidation['lastName']
            )}
          </div>
          <div className='px-2'>
            {renderInputField(
              false,
              'email',
              t('EMAIL_ADDRESS'),
              50,
              formValidation['emailAddress']
            )}
          </div>
        </div>
        <div className='pr-3 pl-3'>
          <div className='overflow-x-auto  overflow-y-auto relative rounded-lg border'>
            <table className='text-left text-[#A5A5A5] w-full text-xs dark:text-gray-400 '>
              <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
                <tr>
                  <th scope='col' className='py-3 px-6 '>
                    {t('SUB_ADMIN_MANAGER')}
                  </th>
                  <th className='py-3 px-6' scope='col'>
                    {t('O_VIEW')}
                  </th>
                  <th scope='col' className='py-3 px-6 '>
                    {t('SUB_ADMIN_ADD')}
                  </th>
                  <th scope='col' className='py-3 px-6 '>
                    {t('SUB_ADMIN_EDIT')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {permissionJson?.map((items, i) => (
                  <tr
                    key={i}
                    className='bg-white dark:bg-gray-800 dark:border-gray-700 border-b'
                  >
                    <td className='py-4 border-r px-6'>
                      {capitalize(startCase(items.manager))}
                    </td>
                    <td className='border-r py-4 px-6'>
                      {items?.showView && (
                        <input
                          id='view'
                          type='checkbox'
                          name={items?.manager}
                          checked={items.view}
                          onChange={onChange}
                        />
                      )}
                    </td>
                    <td className='py-4 px-6 border-r '>
                      {items?.showAdd && (
                        <input
                          type='checkbox'
                          name={items?.manager}
                          id='add'
                          checked={items.add}
                          onChange={onChange}
                        />
                      )}
                    </td>
                    <td className='py-4 px-6 border-r '>
                      {items?.showEdit && (
                        <input
                          type='checkbox'
                          id='edit'
                          name={items?.manager}
                          checked={items.edit}
                          onChange={onChange}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {renderFooter()}
    </>
  )
}

export default SubEdit
