import ErrorMessage from 'components/ErrorMessage'
import React from 'react'

const OInputField = props => {
  const {
    wrapperClassName,
    name,
    businessMargin,
    inputLabel,
    selectOptions,
    type,
    placeholder,
    maxLength = 50,
    register,
    errors,
    ...rest
  } = props

  const getWrapperClassName = (businessMargins, customClassName) => {
    if (businessMargins) {
      return 'relative z-0 w-full group'
    } else if (customClassName) {
      return customClassName
    } else {
      return 'relative z-0 mb-6 w-full group'
    }
  }

  switch (type) {
    case 'hidden':
      return <input type={type} name={name} id={name} {...register} {...rest} />
    case 'text':
    case 'email':
      return (
        <div className={getWrapperClassName(businessMargin, wrapperClassName)}>
          <input
            type={type}
            name={name}
            id={name}
            className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
            placeholder={placeholder ? placeholder : ' '}
            maxLength={maxLength}
            {...register}
            {...rest}
          />
          <label
            htmlFor={name}
            className='dark:bg-gray-800 dark:text dark:bg-gray-800 peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
          >
            {inputLabel}
          </label>
          <ErrorMessage message={errors?.[name]?.message} />
        </div>
      )
    case 'password':
      return (
        <>
          <div
            className={
              wrapperClassName
                ? wrapperClassName
                : 'relative z-0 mb-6 w-full group'
            }
          >
            <input
              autocomplete='new-password'
              type={type}
              name={name}
              id={name}
              className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
              placeholder={placeholder ? placeholder : ' '}
              maxLength={maxLength}
              {...register}
              {...rest}
            />
            <label
              htmlFor={name}
              className='dark:text dark:bg-gray-800 peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
            >
              {inputLabel}
            </label>
            <ErrorMessage message={errors?.[name]?.message} />
          </div>
        </>
      )
    case 'select':
      return (
        <>
          <div
            className={
              wrapperClassName
                ? wrapperClassName
                : 'relative z-0 mb-6 w-full group'
            }
          >
            <select
              id={name}
              className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
              placeholder=' '
              {...register}
              {...rest}
            >
              {selectOptions?.map(item => item)}
            </select>

            <label
              htmlFor={name}
              className='dark:text dark:bg-gray-800 peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
            >
              {inputLabel}
            </label>
          </div>

          <ErrorMessage message={errors?.[name]?.message} />
        </>
      )

    case 'number':
      return (
        <>
          <div
            className={
              wrapperClassName
                ? wrapperClassName
                : 'relative z-0 mb-6 w-full group'
            }
          >
            <input
              type={type}
              id={name}
              className='block py-4 px-3 w-full text-sm text-gray-900 bg-transparent border-2 rounded-lg border-[#DFDFDF] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0  peer'
              placeholder={placeholder ? placeholder : ' '}
              {...register}
              {...rest}
            />
            <label
              htmlFor={name}
              className='dark:text dark:bg-gray-800 peer-focus:font-normal absolute text-sm text-[#A5A5A5] dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 left-3 bg-white p-2 z-10 origin-[2] peer-focus:left-0 peer-focus:text-[#A5A5A5] peer-focus:text-lg peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8'
            >
              {inputLabel}
            </label>
            <ErrorMessage message={errors?.[name]?.message} />
          </div>
        </>
      )

    default:
      return <div>Please provide some input props.</div>
  }
}

export default OInputField
