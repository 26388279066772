const Permission = [
  {
    manager: 'user-manager',
    add: false,
    view: false,
    edit: false,
    showView: true,
    showEdit: false,
    showAdd: false
  },
  {
    manager: 'support-manager',
    add: false,
    view: false,
    edit: false,
    showView: true,
    showEdit: false,
    showAdd: false
  },
  {
    manager: 'emailTemplate',
    add: false,
    view: false,
    edit: false,
    showView: true,
    showEdit: true,
    showAdd: false
  },
  {
    manager: 'faqs',
    add: false,
    edit: false,
    view: false,
    showView: true,
    showEdit: true,
    showAdd: true
  },
  {
    manager: 'manage-static-contents',
    view: false,
    edit: false,
    add: false,
    showAdd: false,
    showView: true,
    showEdit: true
  },
  {
    manager: 'global-setting',
    edit: false,
    add: false,
    view: false,
    showEdit: true,
    showView: true,
    showAdd: false
  },
  {
    manager: 'notification-manager',
    add: false,
    view: false,
    edit: false,
    showView: true,
    showAdd: true,
    showEdit: false
  },
  {
    manager: 'transaction-manager',
    add: false,
    view: false,
    edit: false,
    showView: true,
    showEdit: false,
    showAdd: false
  },
  {
    manager: 'reports-and--analytics-manager',
    add: false,
    view: false,
    edit: false,
    showView: true,
    showEdit: false,
    showAdd: false
  }
]

export default Permission
