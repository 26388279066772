import ErrorMessage from 'components/ErrorMessage'
import useToastContext from 'hooks/useToastContext'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { apiGet, apiPost } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import { useTranslation } from 'react-i18next'
import OInputField from 'components/reusable/OInputField'
import formValidation from 'utils/formValidation'
import Select from 'react-select'
import { preventMaxInput } from 'utils/validations'
import AuthContext from 'context/AuthContext'
import OTextArea from 'components/reusable/OTextArea'

const NotificationAdd = ({ getAllNotifications, handleCategory }) => {
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true
  })
  const notification = useToastContext()
  const { updatePageName } = useContext(AuthContext)
  const [availableFor, setAvailableFor] = useState('all')
  const [userSuggestion, setUserSuggestion] = useState([])
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
  const [userList, setUserList] = useState([])
  const [inputValue, setInputValue] = React.useState('')
  const [addMerchantLoading, setAddMerchantLoading] = useState(false)

  const getAllUser = async () => {
    try {
      const path = apiPath.allNotificationUser
      const payload = {
        keyword: debouncedSearchTerm,
        limit: 5
      }
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        const latest = response.map(element => {
          return { label: element.fullName, value: element._id }
        })
        setUserSuggestion(latest)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleSelectAllUsers = event => {
    if (event.target.checked) {
      setAvailableFor('all')
    }
  }

  const [notificationUserError, setNotificationUserError] = useState(false)

  const handleParticularUser = event => {
    if (event.target.checked) {
      setAvailableFor('specificUser')
    }
  }

  const checkUserSelection = () => {
    let isValid = true
    if (availableFor === 'all') return isValid
    isValid = userList?.length !== 0
    setNotificationUserError(!isValid)
    return isValid
  }

  const onSubmit = async data => {
    setAddMerchantLoading(true)
    try {
      const isValid = checkUserSelection()
      if (!isValid) return
      const obj = {
        ...data,
        sendTo: availableFor
      }
      if (userList.length > 0) {
        obj.user = userList?.map(item => item?.value)
      }

      const res = await apiPost(apiPath.notifications, { ...obj })
      if (res.data.success) {
        handleCategory(false)
        notification.success(res?.data?.message)
        getAllNotifications()
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.error('err:', err)
    } finally {
      setAddMerchantLoading(false)
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm) {
      getAllUser()
    }
  }, [debouncedSearchTerm])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchTerm(inputValue.trim())
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [inputValue])

  useEffect(() => {
    updatePageName(t('ADD_NOTIFICATION'))
  }, [])

  return (
    <div>
      <>
        <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
          <div className='relative my-6 mx-auto'>
            <div className='sm:py-4 sm:px-2 py-8 px-7 '>
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='dark:bg-LightBlue dark:text-white flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h3 className='text-xl font-semibold'>
                    {t('ADD_NOTIFICATION')}
                  </h3>
                  <button
                    className=' ml-auto flex items-center justify-center  text-black border-2 rounded-full  h-8 w-8 float-right text-3xl leading-none font-extralight outline-none focus:outline-none'
                    onClick={() => handleCategory(false)}
                  >
                    <span className=' text-[#B8BBBF]  text-4xl ' title='Close'>
                      ×
                    </span>
                  </button>
                </div>
                <div className='relative p-6 flex-auto dark:bg-gray-800'>
                  <div className=''>
                    <div className='px-2'>
                      <OInputField
                        wrapperClassName=''
                        name='title'
                        maxLength={200}
                        onInput={e => preventMaxInput(e, 200)}
                        inputLabel={
                          <>
                            {t('ENTER_TITLE')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        type='text'
                        autoFocus
                        register={register('title', formValidation.title)}
                        errors={errors}
                      />
                    </div>
                    <div className='px-2'>
                      <OTextArea
                        wrapperClassName='relative z-0 my-6 w-full group'
                        name='description'
                        onInput={e => preventMaxInput(e, 350)}
                        inputLabel={
                          <>
                            {t('NOTIFICATION_DESCRIPTION')}
                            <span className='text-red-500'>*</span>
                          </>
                        }
                        type='textarea'
                        register={register(
                          'description',
                          formValidation.description
                        )}
                        errors={errors}
                      />
                    </div>

                    <div className='px-2'>
                      <div className='relative z-0 mb-6 w-full group flex'>
                        <div className='flex items-center w-[200px]'>
                          <input
                            id='default-checkbox'
                            type='radio'
                            checked={availableFor === 'all'}
                            name='default-radio'
                            onChange={e => handleSelectAllUsers(e)}
                            className='w-4 cursor-pointer h-4 text-blue-600 bg-gray-100 border-gray-300 rounded  dark:bg-gray-700 dark:border-gray-600'
                          />
                          <label
                            for='default-checkbox'
                            className='ml-2 text-sm font-medium cursor-pointer text-gray-900 dark:text-gray-300'
                          >
                            {t('O_ALL_USERS')}
                          </label>
                        </div>

                        <div className='flex items-center w-[200px]'>
                          <input
                            id='default-checkbox1'
                            type='radio'
                            checked={availableFor === 'specificUser'}
                            name='default-radio'
                            onChange={e => handleParticularUser(e)}
                            className='w-4 cursor-pointer h-4 text-blue-600 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600'
                          />
                          <label
                            for='default-checkbox1'
                            className='ml-2 text-sm font-medium cursor-pointer text-gray-900 dark:text-gray-300'
                          >
                            {t('SPECIFIC_USER')}
                          </label>
                        </div>
                      </div>
                    </div>

                    {availableFor === 'specificUser' && (
                      <div className='px-2'>
                        <p className='dark:text-white block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                          {t('SELECT_USERS')}
                        </p>
                        <Select
                          isMulti
                          wrapperClassName='relative z-0 mb-2 w-full group'
                          name='language'
                          inputValue={inputValue}
                          onInputChange={value => setInputValue(value)}
                          placeholder={
                            <>
                              {t('SELECT_USERS_KEYWORD')}
                              <span className='text-red-500'>*</span>
                            </>
                          }
                          options={userSuggestion}
                          defaultValue={t('SELECT_USERS')}
                          onChange={e => {
                            setUserList(e)
                            setNotificationUserError(false)
                          }}
                          value={userList}
                        />
                        {userList.length === 0 && notificationUserError && (
                          <ErrorMessage message='Please select users.' />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className='dark:bg-LightBlue flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-black bg-[#E1E1E1] font-normal px-12 py-2.5 text-sm outline-none focus:outline-none rounded mr-6  ease-linear transition-all duration-150'
                    type='button'
                    title={t('O_BACK')}
                    onClick={() => handleCategory(false)}
                  >
                    {t('O_BACK')}
                  </button>
                  {addMerchantLoading ? (
                    <div className='spinner-container bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'>
                      <div className='loading-spinner'></div>
                    </div>
                  ) : (
                    <button
                      title={t('SEND_NOTIFICATION')}
                      className='bg-LightBlue text-white active:bg-emerald-600 font-normal text-sm px-8 py-2.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150'
                      type='submit'
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t('SEND_NOTIFICATION')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='opacity-25 fixed inset-0 z-40 bg-black' />
      </>
    </div>
  )
}

export default NotificationAdd
