const pathObj = {
  loginUser: '/v1/admin/login',
  getEarnings: '/v1/admin/earnings',
  getUsers: '/v1/admin/user',
  createUser: '/v1/admin/user',
  updateUser: '/v1/admin/user',
  changeUserStatus: '/v1/admin/user/change-status',
  refreshToken: '/v1/user/refresh-token',
  seriesList: '/v1/user/match/series',
  matchList: '/v1/user/match/list',
  refreshAmount: '/v1/admin/refresh-amount',
  userProfile: '/v1/user/profile',
  userProfileUpdate: '/v1/user/profile-update',
  userEditStake: '/v1/user/edit-stake',
  userEditOneClickStake: '/v1/user/edit-one-click-stake',
  activityLogs: '/v1/user/activity-logs',
  transactionLogs: '/v1/user/transaction/logs',
  matchDetail: '/v1/user/match/detail',
  matchScore: '/v1/user/match/score',
  sportsList: '/v1/user/sport/list',
  inPlayCount: '/v1/user/sport/inplay-count',
  betfairPlaceBet: '/v1/user/match/betfaire-place-bet',
  soccerPlaceBet: '/v1/user/match/soccer-place-bet',
  tennisPlaceBet: '/v1/user/match/tennis-place-bet',
  userInPlaySport: '/v1/user/sport',
  bookmakerBet: '/v1/user/match/bookmaker-place-bet',
  betPosition: '/v1/user/match/get-bet-positions',
  betEventsBetPosition: '/v1/user/match/get-events-bet-positions',
  multiEventPlaceBet: '/v1/user/match/multiple-event-place-bet',
  getCurrentBetsExchanges: '/v1/user/match/my-bets',
  cancelUnmatchedBets: '/v1/user/match/clear-unmatched-bet',
  getBookmakerBets: '/v1/user/match/my-bookmaker-bets',
  getFancyBets: '/v1/user/match/my-fancy-bets',
  getSportsBook: '/v1/user/match/my-sport-book-bets',
  fancyBetPlace: '/v1/user/match/fancy-place-bet',
  multiMarket: '/v1/user/sport/multi-market',
  preminumFancyBets: '/v1/user/match/premium-fancy-place-bet',
  addMatchInMultiMarket: '/v1/user/sport/multi-market',
  getPremiumFancy: '/v1/cron/premium-fancy',
  getBetsData: '/v1/user/match/events-bets',
  getHistory: '/v1/user/match/bet-history',
  messageList: '/v1/user/message/list',
  userSubscribe: '/v1/user/subscribe',
  adminSetting: '/v1/user/site-setting/site-setting-list',
  getWalletTransactions: '/v1/admin/wallet-transaction',
  statususer: '/v1/admin/user/change-status',
  getSubAdmin: '/v1/admin/subadmin',
  editSubAdmin: '/v1/admin/subadmin',
  addSubAdmin: '/v1/admin/subadmin',
  deleteSubAdmin: '/v1/admin/subadmin/delete',
  statusSub: '/v1/admin/subadmin/change-status',
  addMerchant: '/v1/admin/merchant',
  editMerchant: '/v1/admin/merchant',
  getMerchantList: '/v1/admin/merchant/list',
  deleteMerchant: '/v1/admin/merchant',
  changeMerchantStatus: '/v1/admin/merchant/status',
  updateSettings: '/v1/admin/setting',
  updateMcrSettings: '/v1/admin/mcr-settings',
  getSettings: '/v1/admin/setting',
  getMcrSettings: '/v1/admin/mcr-settings',
  changeMerchantVerified: '/v1/admin/merchant/verify',
  getFAQs: '/v1/admin/faqs',
  getContactUs: '/v1/admin/contact-us',
  changeFAQStatus: '/v1/admin/faqs/status',
  getStaticContent: '/v1/admin/static-content',
  forgotPassword: '/v1/admin/forgot-password',
  resetPassword: '/v1/admin/reset-password',
  changePassword: '/v1/admin/change-password',
  editProfile: '/v1/admin/edit-profile',
  getAllOffers: '/v1/admin/offers',
  getUserDetails: '/v1/admin/user/details',
  getDashboardDetails: '/v1/admin/dashboard',
  getReports: '/v1/admin/report',
  downloadReport: '/v1/admin/download-report',
  getAgents: '/v1/admin/merchant/agents',
  getMicroCreditLoanSlabs: '/v1/admin/micro-credit-loan-slabs',
  getMicroCreditLoanRequests: '/v1/admin/loans',
  ChangeStatusMicroCreditLoanRequests: '/v1/admin/loans/change-status',
  subcategoryList: '/v1/admin/subcategory',
  subCategoryStatus: '/v1/admin/subcategory/change-status',
  deleteSubCategory: '/v1/admin/subcategory',
  subcategoryAdd: '/v1/admin/subcategory',
  subcategoryUpdate: '/v1/admin/subcategory',
  categoryList: '/v1/admin/category',
  categoryAllList: '/v1/admin/category/list',
  categoryStatus: '/v1/admin/category/change-status',
  deleteCategory: '/v1/admin/category',
  categoryAdd: '/v1/admin/category',
  categoryUpdate: '/v1/admin/category',
  orders: '/v1/admin/orders',
  changeOfferStatus: '/v1/admin/offers/change-status',
  categoryGet: '/v1/admin/category/list',
  getCategoryList: '/v1/merchant/list',
  emailTemplate: '/v1/admin/email-template',
  bannerList: '/v1/admin/banner',
  bannerChangeStatus: '/v1/admin/banner/change-status',
  changeEmailStatus: 'v1/admin/email-template/change-status',
  escrowsList: '/v1/admin/escrows',
  escrowsPay: '/v1/admin/escrows/pay',
  couponListing: '/v1/admin/coupons',
  couponStatus: '/v1/admin/coupons/change-status',
  sampleCouponFile: '/v1/admin/coupons/sample',
  notification: '/v1/admin/notification',
  generateAccessToken: '/v1/admin/gift-card/generate-access-token',
  productList: '/v1/admin/gift-card/products-list',
  giftCardChangeStatus: '/v1/admin/gift-card/change-status',
  staticContentStatus: '/v1/admin/static-content/change-status',
  updateSequence: '/v1/admin/faqs/update-sequence',
  transactionTypeList: '/v1/admin/transaction/type-list',
  walletTransactionTypeList: '/v1/admin/wallet-transaction/type-list',
  allCoupon: '/v1/admin/coupons/codes',
  getSupport: '/v1/admin/support',
  sendFeedbackEmail: 'v1/admin/support',
  notificationToggle: 'v1/admin/setting',
  getLogs: 'v1/admin/logs',
  getProfileList: 'v1/admin/',
  notifications: 'v1/admin/notification',
  allNotificationUser: 'v1/admin/user/all-users',
  getTransactions: '/v1/admin/transaction',
  getRemittanceTransactions: '/v1/admin/transaction/remittance',
  getTransactionsHistory: '/v1/admin/reports/transaction',
  getUserActivityReports: '/v1/admin/reports/user-activity',
  getUserActivityGraph: '/v1/admin/reports/user-activity'
}

export default pathObj
