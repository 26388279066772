import OImage from 'components/reusable/OImage'
import dayjs from 'dayjs'
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, Link } from 'react-router-dom'
import defaultImage from '../../assets/images/No-image-found.jpg'
import { startCase } from 'lodash'
import { IoArrowBackSharp } from 'react-icons/io5'
import AuthContext from 'context/AuthContext'
import fullNameIcon from '../../assets/icons/fullName.svg'
import InfoIcon from '../../assets/icons/info.svg'
import emailIcon from '../../assets/icons/email.svg'
import mobileIcon from '../../assets/icons/mobile.svg'

const UserView = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const [item] = useState(location.state)
  const { updatePageName } = useContext(AuthContext)

  useEffect(() => {
    updatePageName(t('VIEW_USER_MANAGER'))
  }, [])

  const ProfileInfo = ({ items }) => {
    return (
      <>
        <div className='flex items-center mb-5'>
          {/* <div className='flex mr-5'>
            <figure className='inline-block overflow-hidden rounded-full border border-2 mb-5'>
              <OImage
                src={items?.idPhoto || defaultImage}
                className='w-[100px] h-[100px] inline'
                alt=''
              />
            </figure>
          </div> */}

          <div className='dark:text-white dark:bg-LightBlue grid grid-cols-4 bg-[#F2F2F2] rounded-lg p-4 w-[calc(100%_-_135px)] mr-4 px-8 flex justify-between'>
            <div className='flex items-center'>
              <figure className='bg-white w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={fullNameIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5 C5C5C]'>Full name</span>
                <strong className='text-sm'>
                  {' '}
                  {startCase(`${items?.firstName} ${items?.lastName}`) || 'N/A'}
                </strong>
              </figcaption>
            </div>

            <div className='flex items-center'>
              <figure className='bg-white w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={emailIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5 C5C5C]'>Email ID</span>
                <strong className='text-sm'> {items?.email || 'N/A'}</strong>
              </figcaption>
            </div>

            <div className='flex items-center ml-10'>
              <figure className='bg-white w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={mobileIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5 C5C5C]'>Mobile number</span>
                <strong className='text-sm'>
                  {' '}
                  {'+'} {items?.countryCode} {items?.mobile || 'N/A'}
                </strong>
              </figcaption>
            </div>
          </div>
        </div>
      </>
    )
  }

  const UserDetails = ({ items, translate }) => {
    return (
      <>
        <div className='user-details'>
          <div className='border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full'>
            <div className='flex items-center mb-4'>
              <figure className='bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5C5C5C] dark:text-white'>
                  {translate('O_USER_ID')}
                </span>
                <strong className='text-sm'>{items?.userId ?? 'N/A'}</strong>
              </figcaption>
            </div>

            <div className='flex items-center mb-4'>
              <figure className='bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5C5C5C] dark:text-white'>
                  {translate('ID_NUMBER')}
                </span>
                <strong className='text-sm'>{items?.idNumber ?? 'N/A'}</strong>
              </figcaption>
            </div>

            <div className='flex items-center mb-4'>
              <figure className='bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5C5C5C] dark:text-white'>
                  {translate('PROVINCE_STATE')}
                </span>
                <strong className='text-sm'>{items?.state ?? 'N/A'}</strong>
              </figcaption>
            </div>

            <div className='flex items-center mb-4'>
              <figure className='bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5C5C5C] dark:text-white'>{translate('DOB')}</span>
                <strong className='text-sm'>
                  {' '}
                  {dayjs(items?.dob).format('DD/MM/YYYY') ?? 'N/A'}
                </strong>
              </figcaption>
            </div>
          </div>
        </div>
      </>
    )
  }

  const SubscriptionInfo = ({ items, translate }) => {
    return (
      <>
        <div className='user-details'>
          <div className='border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full'>
            <div className='flex items-center mb-4'>
              <figure className='bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5C5C5C] dark:text-white'>
                  {translate('COUNTRY')}
                </span>
                <strong className='text-sm'>
                  {startCase(items.country) || 'N/A'}
                </strong>
              </figcaption>
            </div>

            <div className='flex items-center mb-4'>
              <figure className='bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5C5C5C] dark:text-white'>
                  {translate('CITY')}
                </span>
                <strong className='text-sm'>
                  {' '}
                  {startCase(items?.city) || 'N/A'}
                </strong>
              </figcaption>
            </div>

            <div className='flex items-center mb-4'>
              <figure className='bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5C5C5C] dark:text-white'>
                  {translate('SUB_ADMIN_ADDRESS')}
                </span>
                <strong className='text-sm'> {items?.address ?? 'N/A'}</strong>
              </figcaption>
            </div>

            <div className='flex items-center mb-4'>
              <figure className='bg-[#F2F2F2] w-[42px] h-[41px] rounded-full flex items-center justify-center mr-3'>
                <img src={InfoIcon} alt='' />
              </figure>
              <figcaption className='w-[calc(100%_-_41px)]'>
                <span className='block text-[#5C5C5C] dark:text-white'>
                  {translate('ADDRESS_2')}
                </span>
                <strong className='text-sm'>
                  {items?.addressAdditional ?? 'N/A'}
                </strong>
              </figcaption>
            </div>
          </div>
        </div>
      </>
    )
  }
  const ImageInfo = ({ items, translate }) => {
    return (
      <>
        <div className='user-details'>
          <div className='border border-1 border-[#E1DEDE] rounded-md p-6 ps-3 h-full'>
            <span className='block text-center pb-3'>
              {translate('ID_PHOTO')}
            </span>
            <div className='relative'>
              <figure className='inline-block overflow-hidden border mb-3 w-full h-[200px]'>
                <OImage
                  src={item?.idPhoto || defaultImage}
                  className='w-full h-full object-contain inline '
                  alt=''
                  fallbackUrl={defaultImage}
                />
              </figure>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
   <div className='px-3 py-4 dark:bg-LightBlue'>
     <div className='p-5 dark:bg-gray-800 rounded-md'>
      <Link to='/user-manager' className='mb-5 ml-4 block dark:text-white' title='Back'>
        <IoArrowBackSharp />
      </Link>

      <div className=''>
        <ProfileInfo items={item} />
        <div className='grid grid-cols-3 gap-5 dark:text-white'>
          <UserDetails items={item} translate={t} />
          <SubscriptionInfo items={item} translate={t} />
          <ImageInfo items={item} translate={t} />
        </div>
      </div>
    </div>
   </div>
  )
}

export default UserView
