import React from 'react'
import { isEmpty } from 'lodash'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { AiFillEye } from 'react-icons/ai'

const Table = ({ logs, user, page, sort, setSort, manager, handleView }) => {
  const { t } = useTranslation()

  return (
    <div className='p-3'>
      <div className='relative overflow-x-auto rounded-lg border'>
        <table className='w-full text-xs dark:text-gray-400 text-left text-[#A5A5A5]'>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th scope='col' className='text-left py-3 px-6'>
                {t('USERS_FULL_NAME')}
              </th>
              <th scope='col' className='text-left py-3 px-6 '>
                {t('USER_ID')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_EMAIL')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_MOBILE_NUMBER')}
              </th>
              <th className='text-left py-3 px-6' scope='col'>
                {t('TYPE')}
              </th>
              <th className='py-3 px-6 text-left' scope='col'>
                {t('DEVICE_TYPE')}
              </th>
              <th className='py-3 px-6 text-left' scope='col'>
                {t('CREATED_DATE')}
              </th>

              <th scope='col' className='py-3 px-6'>
                {t('O_ACTION')}
              </th>
            </tr>
          </thead>
          <tbody>
            {logs &&
              logs?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 text-gray-900 border-r'>
                    {item?.user?.firstName && item?.user?.lastName
                      ? `${item?.user?.firstName} ${item?.user?.lastName}`
                      : 'N/A'}
                  </td>
                  <td className='text-gray-900 py-4 px-6 border-r text-left'>
                    {item?.user?.userId || 'N/A'}
                  </td>
                  <td className='py-4 px-6 text-gray-900 border-r text-left'>
                    {item?.user?.email || 'N/A'}
                  </td>
                  <td className='py-4 px-6 border-r text-gray-900'>
                    {item?.user?.countryCode && '+' + item?.user?.countryCode}{' '}
                    {item?.user?.mobile || 'N/A'}
                  </td>
                  <td className='text-gray-900 py-4 px-6 border-r text-left'>
                    {item?.type || 'N/A'}
                  </td>
                  <td className='py-4 px-6 text-gray-900 border-r text-left'>
                    {item?.deviceType || 'N/A'}
                  </td>
                  <td className='py-4 text-gray-900 px-6 border-r'>
                    {dayjs(item?.user?.createdAt).format('DD/MM/YYYY hh:mm A')}{' '}
                  </td>
                  <td className='py-4 px-6 border-l'>
                    <div className=''>
                      <ul className='flex justify-center'>
                        {(manager?.view || user?.role === 'admin') && (
                          <li
                            onClick={() => handleView(item)}
                            className='px-2 py-2 hover:bg-white dark:hover:bg-transparent hover:text-LightBlue '
                          >
                            <a title='View logs'>
                              {' '}
                              <AiFillEye className='cursor-pointer w-5 h-5 text-slate-600' />{' '}
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            {isEmpty(logs) ? (
              <tr className='dark:bg-gray-800 dark:border-gray-700 bg-white text-center border-b '>
                <td className='py-4 px-6 border-r' colSpan={9}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table
