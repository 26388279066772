import { useContext, useEffect, useState } from 'react'
import { apiGet, apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import ReactQuill from 'react-quill'
import useToastContext from 'hooks/useToastContext'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import OInputField from 'components/reusable/OInputField'
import formValidation from 'utils/formValidation'
import { preventMaxInput } from 'utils/validations'

const PrivatePolicy = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      title: location?.state?.title
    }
  })
  const [content, setContent] = useState('')
  const [updateDate, setUpdateDate] = useState()
  const notification = useToastContext()
  const { logoutUser, updatePageName } = useContext(AuthContext)
  const [pageType] = location?.state?.pageType

  const getStaticContent = async () => {
    try {
      const path = apiPath.getStaticContent
      const payload = {
        slug: 'privacy-policy'
      }
      const result = await apiGet(path, payload)
      if (result?.status === 200) {
        const response = result?.data?.results
        setContent(response?.content)
        setUpdateDate(response?.updatedAt)
      }
    } catch (error) {
      console.error('error in get all FAQs list==>>>', error.message)

      if (error.response.status === 409 || error.response.status === 401) {
        logoutUser()
      }
    }
  }
  const updateStaticContent = async () => {
    try {
      const formValue = getValues()
      const data = {
        content,
        title: formValue?.title?.trim()
      }
      const path = apiPath.getStaticContent + '?' + 'slug=privacy-policy'
      const result = await apiPut(path, data)
      if (result?.status === 200) {
        navigate('/manage-static-contents')
        notification.success(result?.data?.message)
        getStaticContent()
      }
    } catch (error) {
      console.error('error in get all static content list==>>>>', error.message)
    }
  }

  useEffect(() => {
    getStaticContent()
  }, [])

  useEffect(() => {
    updatePageName(t('NAV_PRIVACY_POLICY'))
  }, [])
  return (
    <div>
      <div className='bg-[#F9F9F9]'>
        <div className='px-3 py-4 dark:bg-LightBlue'>
          <div className='bg-white border border-[#E9EDF9] rounded-lg dark:bg-gray-800'>
            <div className=' border-b-[#E3E3E3] grid 2xl:grid-cols-3 xl:grid-cols-2 lg:grid lg:grid-cols-1 gap-2 px-4 '>
              <div className='col-span-2 flex flex-wrap  items-center' />
            </div>

            <div className='p-5 shadow-md'>
              <OInputField
                type='text'
                autoFocus
                wrapperClassName='relative z-0 mb-6 w-full group'
                name='title'
                maxLength={55}
                onInput={e => preventMaxInput(e, 55)}
                inputLabel={
                  <>
                    {t('STATIC_PAGE_TITLE')}
                    <span className='text-red-500'>*</span>
                  </>
                }
                register={register('title', formValidation['title'])}
                errors={errors}
              />
              <ReactQuill
                value={content}
                onChange={setContent}
                className='dark:text-white'
                placeholder='Static page description'
              />
            </div>
          </div>
          <p style={{ marginTop: '10px', marginLeft: '10px' }} className='dark:text-white'>
            {t('LAST_UPDATE')}:- {dayjs(updateDate).format('llll')}
          </p>
          {pageType === 'e' && (
            <div className='2xl:ml-auto xl:ml-0 lg:pt-0 pt-2'>
              <div className='flex'>
                <button
                  title={t('O_BACK')}
                  className='bg-[#E1E1E1] flex my-4 text-sm px-8 ml-3 py-2 rounded-lg items-center border border-transparent text-black  whitespace-nowrap'
                  type='button'
                  onClick={() => navigate('/manage-static-contents')}
                >
                  {t('O_BACK')}
                </button>
                <button
                  title={t('O_SAVE')}
                  onClick={handleSubmit(updateStaticContent)}
                  className='dark:bg-gray-800 bg-LightBlue flex my-4 text-sm px-8 ml-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue whitespace-nowrap'
                >
                  {t('O_SAVE')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default PrivatePolicy
