import React, { Fragment, useContext, useEffect } from 'react'
import { Transition, Menu } from '@headlessui/react'
import AuthContext from '../context/AuthContext'
import { Link } from 'react-router-dom'
import OImage from './reusable/OImage'
import { useTranslation } from 'react-i18next'

const TopNavBar = () => {
  const { t } = useTranslation()
  const {
    logoutUser,
    pageName,
    updatePageName,
    setSideBarOpen,
    sideBarOpen,
    getProfileList,
    profileData
  } = useContext(AuthContext)

  const handleLogout = () => {
    if (window.confirm('Are you sure to sign-out?')) {
      logoutUser()
    }
  }
  useEffect(() => {
    getProfileList()
  }, [])

  return (
    <div className='linHeader border-b py-4 px-4 md:px-8 dark:bg-LightBlue'>
      <header className='bg-white items-center flex dark:bg-LightBlue'>
        <div
          className='mr-4 cursor-pointer dark:text-white'
          onClick={() => setSideBarOpen(!sideBarOpen)}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='w-6 h-6 dark:text-white'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
            />
          </svg>
        </div>

        <div className='page_heading '>
          <h1 className='flex items-center text-lg text-slate-900 font-medium dark:text-white'>
            {pageName}
          </h1>
        </div>
        <Menu
          as='div'
          className='relative inline-block text-left hover:text-LightBlue sidebar_dropdown ml-auto'
        >
          <div>
            <Menu.Button className='flex relative items-center w-full text-white active:text-LightBlue hover:bg-white myAccountHover hover:text-LightBlue group'>
              <div className='profile flex items-center ml-auto md:mr-10 mr-2'>
                <figure className='inline-block overflow-hidden rounded-full'>
                  <OImage
                    src={profileData?.profilePic}
                    fallbackUrl='/images/No-image-found.jpg'
                    className='w-[40px] h-[40px] inline'
                    alt=''
                  />
                </figure>
                <small className='block text-sm text-slate-900 ml-2 md:block hidden'>
                  {profileData?.firstName} {profileData?.lastName}
                </small>
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter='transition ease-out duration-10'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute z-10 text-left min-w-[200px]  right-2 content divide-y divide-gray-100  bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              <div className=''>
                <Menu.Item onClick={() => updatePageName(t('MY_PROFILE'))}>
                  {({ active }) => (
                    <Link
                      to='/profile'
                      className={`${
                        active ? 'bg-DarkBlue text-white' : 'text-gray-900'
                      } group flex w-full items-center  px-6 py-2 text-sm`}
                    >
                      {t('MY_PROFILE')}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item
                  onClick={() => updatePageName(t('PROFILE_CHANGE_PASSWORD'))}
                >
                  {({ active }) => (
                    <Link
                      to='/change-password'
                      className={`${
                        active ? 'bg-DarkBlue text-white' : 'text-gray-900'
                      } group flex w-full items-center  px-6 py-2 text-sm`}
                    >
                      {t('PROFILE_CHANGE_PASSWORD')}
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className=''>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={handleLogout}
                      className={`${
                        active ? 'bg-DarkBlue text-white' : 'text-gray-900'
                      } group flex w-full items-center  px-6 py-2 text-sm`}
                    >
                      {t('NAV_LOGOUT')}
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        {/* <a
                    href='#'
                    className='bg-LightBlue flex text-sm px-3 py-2 rounded-lg items-center border border-transparent text-white hover:bg-DarkBlue'
                >
                    <img src='./images/google-play.svg' className='pr-2' alt='' />
                    Download App
                </a> */}
      </header>
    </div>
  )
}

export default TopNavBar
