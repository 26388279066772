import ErrorMessage from '../../components/ErrorMessage'
import React, { useContext, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { apiGet, apiPut } from '../../utils/apiFetch'
import pathObj from '../../utils/pathObj'
import useToastContext from 'hooks/useToastContext'
import OButton from 'components/reusable/OButton'
import { useTranslation } from 'react-i18next'
import AuthContext from 'context/AuthContext'
import OInputField from 'components/reusable/OInputField'
import imageDefault from '../../assets/images/No-image-found.jpg'
import OImage from 'components/reusable/OImage'
import { Link } from 'react-router-dom'
import Credential from './Credential'
import { validationRules } from 'utils/constants'

const Settings = () => {
  const { logoutUser, user, updatePageName } = useContext(AuthContext)
  const manager =
    user?.permission?.find(e => e.manager === 'global-setting') ?? {}
  const { t } = useTranslation()
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty, errors }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {}
  })
  const [settingChangeLoading, setSettingChangeLoading] = useState(false)
  const [pic] = useState(user?.profilePic ?? imageDefault)
  const [viewShowModal, setViewShowModal] = useState(false)
  const notification = useToastContext()
  const [switchStatus, setSwitchStatus] = useState(false)

  const handleSubmitForm = async data => {
    try {
      setSettingChangeLoading(true)
      data.conversion_rate = parseInt(data.conversion_rate)
      data.buysCoupon = parseInt(data.buysCoupon)
      data.giftCard = parseInt(data.giftCard)
      const res = await apiPut(pathObj.updateSettings, data)
      if (res.data.success === true) {
        getSettings()

        notification.success(res?.data?.message)
      } else {
        notification.error(res?.data?.message)
      }
    } catch (err) {
      console.error('err:', err)
    } finally {
      setSettingChangeLoading(false)
    }
  }
  const handleUserView = () => {
    setViewShowModal(true)
  }
  const getSettings = async () => {
    try {
      const res = await apiGet(pathObj.getSettings)
      if (res) {
        const settingData = {
          ...res?.data?.results,
          email: res?.data?.results?.adminEmail
        }
        reset(settingData)
        setSwitchStatus(res?.data?.results?.logging)
      }
    } catch (error) {
      console.error('error:', error)

      if (error.response.status === 401 || error.response.status === 409) {
        logoutUser()
      }
    }
  }

  useEffect(() => {
    getSettings()
  }, [])
  const preventMax = e => {
    if (e.target.value.length > 10) {
      e.target.value = e.target.value.slice(0, 10)
    }
  }
  const preventMaxValue = e => {
    if (e.target.value.length > 5) {
      e.target.value = e.target.value.slice(0, 5)
    }
  }
  const preventMaxHundred = e => {
    if (e.target.value > 100) {
      e.target.value = e.target.value.slice(0, 2)
    }
  }
  useEffect(() => {
    updatePageName(t('NAV_SETTINGS'))
  }, [])

  const handleKeyDown = event => {
    if (
      !['Backspace', 'Delete', 'Tab'].includes(event.key) &&
      !/[0-9.]/.test(event.key)
    ) {
      event.preventDefault()
    }
  }

  const handleKeyDownCashIn = e => {
    pressedKeys.push(e.key)
    var lastKey = pressedKeys[pressedKeys.length - 2]

    const isInvalidKey = ['-', '+', 'e'].includes(e.key)
    const isDotKey = e.key === '.'

    if (lastKey === '.') {
      if (isInvalidKey || isDotKey) {
        e.preventDefault()
      }
    } else if (isInvalidKey) {
      e.preventDefault()
    }

    if (
      !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
      e.target.value?.split('.')[1]?.length >= 2
    ) {
      e.preventDefault()
    }
  }

  var pressedKeys = []

  const switchChange = async event => {
    try {
      const path = pathObj.notificationToggle
      const toggleValue = event.target.checked
      const payload = {
        logging: toggleValue
      }
      const { data: response } = await apiPut(path, payload)
      if (response?.success) {
        notification.success(response?.message)
        getSettings()
        localStorage.setItem('notifications', toggleValue)
      } else {
        notification.error(response?.message || 'Error')
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <section className=''>
      <form>
        <section className='sm:px-8 px-4 py-4 dark:bg-LightBlue'>
          <div className='border xl:w-full round'>
            <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md dark:bg-gray-800'>
              <div className='font-semibold dark:text-white'>Global setting</div>
            </header>
            <div className='bg-white py-6 px-4  rounded-b-md dark:bg-gray-800'>
              <div className='mb-4  w-full flex justify-between px-3'>
                <div className='justify-center flex items-center'>
                  <div className='relative w-24 h-24 '>
                    <OImage
                      src={pic}
                      fallbackUrl='/images/user.png'
                      className='w-full h-full rounded-full'
                      alt=''
                    />
                  </div>
                  <div className='pl-6  w-full2 flex align-center'>
                    <div>
                      {(manager?.edit || user?.role === 'admin') && (
                        <Link to='/change-password'>
                          {' '}
                          <OButton
                            label={<>Change Password</>}
                            type='button'
                            loading={settingChangeLoading}
                          />
                        </Link>
                      )}
                    </div>

                    <div className='  '>
                      {(manager?.edit || user?.role === 'admin') && (
                        <OButton
                          label={<>View Login Credentials</>}
                          type='button'
                          onClick={() => handleUserView()}
                          loading={settingChangeLoading}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className='flex items-center'>
                  <span class='dark:text-white text-base md:text-xl font-semibold block mr-3 md:mr-5'>
                    {t('SETTING_NOTIFICATIONS')}
                  </span>
                  <div className='pt-2'>
                    <label className='relative inline-flex items-center cursor-pointer'>
                      <input
                        className='sr-only peer'
                        type='checkbox'
                        id='custom-switch'
                        onChange={switchChange}
                        checked={switchStatus}
                      />
                      <div className="w-11 h-6 bg-[#A7A4B2] peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                    </label>
                  </div>
                </div>
              </div>

              <main className='justify-center aline-center flex flex-wrap grid  lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4' />
            </div>
          </div>
        </section>
      </form>

      <div className='border xl:w-full round'>
        <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
          <div className='font-semibold'>Money transfer setting</div>
        </header>
        <div className='bg-white py-6 px-4  rounded-b-md dark:bg-gray-800'>
          <main className='justify-center flex flex-wrap grid  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4'>
            <div className='relative z-0 mb-6 w-full group'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                inputLabel={<>Minimum withdrawal amount</>}
                id='minimumWithdrawalAmount'
                onInput={e => preventMax(e)}
                onKeyDown={event => handleKeyDown(event)}
                register={register('minimumWithdrawalAmount', {
                  required: {
                    value: true,
                    message: 'Please enter minimum withdrawal amount.'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'Decimals not allowed.'
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum limit is 10 characters.'
                  },
                  min: {
                    value: 1,
                    message: 'Minimum value must is 1.'
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage
                message={errors?.minimumWithdrawalAmount?.message}
              />
            </div>
            <div className='w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                inputLabel={<>Add money limit (minimum)</>}
                name='addMoneyMin'
                onInput={e => preventMaxValue(e)}
                onKeyDown={event => handleKeyDown(event)}
                register={register('addMoneyMin', {
                  required: {
                    value: true,
                    message: 'Please enter minimum add money limit.'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'Only digits are allowed.'
                  },
                  maxLength: {
                    value: 5,
                    message: 'Maximum limit is 5 characters.'
                  },
                  min: {
                    value: 1,
                    message: 'Minimum value must is 1.'
                  }
                })}
                placeholder=''
              />

              <ErrorMessage message={errors?.addMoneyMin?.message} />
            </div>

            <div className=' w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                inputLabel={<>Add money limit (maximum)</>}
                id='addMoneyMax'
                onInput={e => preventMaxValue(e)}
                onKeyDown={event => handleKeyDown(event)}
                register={register('addMoneyMax', {
                  required: {
                    value: true,
                    message: 'Please enter maximum add money limit.'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'Only digits are allowed.'
                  },
                  maxLength: {
                    value: 5,
                    message: 'Maximum limit is 5 characters.'
                  },
                  min: {
                    value: 1,
                    message: 'Minimum value must is 1.'
                  },
                  validate: val => {
                    const { addMoneyMin } = getValues()
                    if (Number(addMoneyMin) > Number(val)) {
                      return 'Maximum add money limit should be greater than minimum add money limit.'
                    }
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage message={errors?.addMoneyMax?.message} />
            </div>

            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                inputLabel={<>Transfer money limit (minimum)</>}
                id='transferMoneyMin'
                onInput={e => preventMax(e)}
                onKeyDown={event => handleKeyDown(event)}
                register={register('transferMoneyMin', {
                  required: {
                    value: true,
                    message: 'Please enter minimum transfer money limit.'
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum limit is 10 characters.'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'Only digits are allowed.'
                  },
                  min: {
                    value: 1,
                    message: 'Minimum value must is 1.'
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage message={errors?.transferMoneyMin?.message} />
            </div>

            <div className='w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                id='payment'
                inputLabel={<>Transfer money limit (maximum)</>}
                onInput={e => preventMax(e)}
                onKeyDown={event => handleKeyDown(event)}
                register={register('transferMoneyMax', {
                  required: {
                    value: true,
                    message: 'Please enter maximum transfer money limit.'
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum limit is 10 characters.'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'Only digits are allowed.'
                  },
                  min: {
                    value: 1,
                    message: 'Minimum value must is 1.'
                  },
                  validate: val => {
                    const { transferMoneyMin } = getValues()
                    if (Number(transferMoneyMin) > Number(val)) {
                      return 'Maximum transfer money limit should be greater than minimum transfer money limit.'
                    }
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage message={errors?.transferMoneyMax?.message} />
            </div>
          </main>
        </div>
      </div>

      <div className='border xl:w-full round'>
        <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
          <div className='font-semibold'>Commission Setting</div>
        </header>
        <div className='bg-white py-6 px-4  rounded-b-md dark:bg-gray-800'>
          <main className='justify-center flex flex-wrap grid  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4'>
            <div className='relative z-0 mb-6 w-full group'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                inputLabel={<>Cash in (%)</>}
                id='cashInCommission'
                onKeyDown={e => {
                  pressedKeys.push(e.key)
                  var lastKey = pressedKeys[pressedKeys.length - 2]
                  if (lastKey == '.') {
                    if (['-', '.', '+', 'e'].includes(e.key)) {
                      e.preventDefault()
                    }
                  } else if (['-', '+', 'e'].includes(e.key)) {
                    e.preventDefault()
                  }
                  if (
                    !['Backspace', 'Delete', 'Tab'].includes(e.key) &&
                    e.target.value?.split('.')[1]?.length >= 2
                  ) {
                    e.preventDefault()
                  }
                }}
                type='number'
                step='any'
                onInput={e => preventMaxHundred(e)}
                register={register('cashInCommission', {
                  required: {
                    value: true,
                    message: 'Please enter cash in commission.'
                  },
                  min: {
                    value: 0,
                    message: 'Minimum value must is 0.'
                  }
                })}
              />
              <ErrorMessage message={errors?.cashInCommission?.message} />
            </div>
            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                maxLength={40}
                id='cashOutCommission'
                inputLabel={<>Cash out(%)</>}
                onKeyDown={e => handleKeyDownCashIn(e)}
                step='any'
                onInput={e => preventMaxHundred(e)}
                register={register('cashOutCommission', {
                  required: {
                    value: true,
                    message: 'Please enter cash out commission.'
                  },
                  min: {
                    value: 0,
                    message: 'Minimum value must is 0.'
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage message={errors?.cashOutCommission?.message} />
            </div>
            <div className='relative z-0 mb-6 w-full group'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                maxLength={40}
                inputLabel={<>Transfer to bank (customer)(%)</>}
                id='transferToBankCommission'
                step='any'
                onInput={e => preventMaxHundred(e)}
                onKeyDown={event => handleKeyDownCashIn(event)}
                register={register('transferToBankCommission', {
                  required: {
                    value: true,
                    message:
                      'Please enter transfer to bank (customer) commission.'
                  },
                  min: {
                    value: 0,
                    message: 'Minimum value must is 0.'
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage
                message={errors?.transferToBankCommission?.message}
              />
            </div>
            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                maxLength={40}
                id='spreadMarginRate'
                inputLabel={<>Spread margin rate(%)</>}
                onKeyDown={e => handleKeyDownCashIn(e)}
                step='any'
                onInput={e => preventMaxHundred(e)}
                register={register('spreadMarginRate', {
                  required: {
                    value: true,
                    message: 'Please enter spread margin rate.'
                  },
                  min: {
                    value: 0,
                    message: 'Minimum value must is 0.'
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage message={errors?.spreadMarginRate?.message} />
            </div>
          </main>
        </div>
      </div>

      <div className='border xl:w-full round'>
        <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
          <div className='font-semibold'>Loyalty & Referral Points</div>
        </header>
        <div className='bg-white py-6 px-4  rounded-b-md dark:bg-gray-800'>
          <main className='justify-center flex flex-wrap grid  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4'>
            <div className='relative z-0 mb-6 w-full group'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='text'
                inputLabel={<>Number of loyalty points (Points)</>}
                id='conversionPoint'
                onInput={e => preventMax(e)}
                onKeyDown={event => handleKeyDown(event)}
                autoComplete='off'
                register={register('conversionPoint', {
                  required: {
                    value: true,
                    message: 'Please enter number of loyalty points.'
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum limit is 10 characters.'
                  },
                  min: {
                    value: 1,
                    message: 'Minimum value must is 1.'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'Decimals not allowed.'
                  }
                })}
              />
              <ErrorMessage message={errors?.conversionPoint?.message} />
            </div>
            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                id='conversionRate'
                inputLabel={<>Conversion rate of loyalty point</>}
                onInput={e => preventMax(e)}
                onKeyDown={event => handleKeyDown(event)}
                register={register('conversionRate', {
                  required: {
                    value: true,
                    message: 'Please enter conversion rate of loyalty points.'
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum limit is 10 characters.'
                  },
                  min: {
                    value: 1,
                    message: 'Minimum value must is 1.'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'Decimals not allowed.'
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage message={errors?.conversionRate?.message} />
            </div>
            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                step='any'
                id='loyaltyPointPercent'
                inputLabel={<>Loyalty point (%)</>}
                onInput={e => preventMaxHundred(e)}
                onKeyDown={e => handleKeyDownCashIn(e)}
                register={register('loyaltyPointPercent', {
                  required: {
                    value: true,
                    message: 'Please enter loyalty points.'
                  },
                  min: {
                    value: 0.01,
                    message: 'Minimum value must is 0.01.'
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage message={errors?.loyaltyPointPercent?.message} />
            </div>
            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                id='thresholdValueToRedeemLoyaltyPoint'
                inputLabel={<>Threshold value to redeem loyalty point</>}
                onInput={e => preventMax(e)}
                onKeyDown={event => handleKeyDown(event)}
                register={register('thresholdValueToRedeemLoyaltyPoint', {
                  required: {
                    value: true,
                    message:
                      'Please enter threshold value to redeem loyalty point.'
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum limit is 10 characters.'
                  },
                  min: {
                    value: 1,
                    message: 'Minimum value must is 1.'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'Decimals not allowed.'
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage
                message={errors?.thresholdValueToRedeemLoyaltyPoint?.message}
              />
            </div>
            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                id='referralAmount'
                inputLabel={<>Referral amount</>}
                onInput={e => preventMax(e)}
                onKeyDown={event => handleKeyDown(event)}
                register={register('referralAmount', {
                  required: {
                    value: true,
                    message: 'Please enter referral amount.'
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum limit is 10 characters.'
                  },
                  min: {
                    value: 1,
                    message: 'Minimum value must is 1.'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'Decimals not allowed.'
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage message={errors?.referralAmount?.message} />
            </div>
            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='number'
                id='thresholdValueToRedeemReferralPoint'
                inputLabel={<>Threshold value to redeem referral point</>}
                onInput={e => preventMax(e)}
                onKeyDown={event => handleKeyDown(event)}
                register={register('thresholdValueToRedeemReferralPoint', {
                  required: {
                    value: true,
                    message:
                      'Please enter threshold value to redeem referral point.'
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum limit is 10 characters.'
                  },
                  min: {
                    value: 1,
                    message: 'Minimum value must is 1.'
                  },
                  pattern: {
                    value: /^\d+$/,
                    message: 'Decimals not allowed.'
                  }
                })}
                placeholder=' '
              />
              <ErrorMessage
                message={errors?.thresholdValueToRedeemReferralPoint?.message}
              />
            </div>
          </main>
        </div>
      </div>

      <div className='border xl:w-full round'>
        <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
          <div className='font-semibold'>Contact-Us</div>
        </header>
        <div className='bg-white py-6 px-4  rounded-b-md'>
          <main className='justify-center flex flex-wrap grid  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4'>
            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='email'
                name='adminEmail'
                id='adminEmail'
                inputLabel={<>Admin contact email</>}
                step='any'
                register={register('adminEmail', {
                  required: 'Please enter contact-us email ID.',
                  pattern: {
                    value: validationRules.email,
                    message:
                      'Please enter valid email ID as: example@domain.com.'
                  }
                })}
              />
              <ErrorMessage message={errors?.adminEmail?.message} />
            </div>
          </main>
        </div>
      </div>

      <div className='border xl:w-full round'>
        <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
          <div className='font-semibold'>Social Media Links</div>
        </header>
        <div className='bg-white py-6 px-4  rounded-b-md'>
          <main className='justify-center flex flex-wrap grid  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4'>
            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='text'
                name='adminTwitterLink'
                id='adminTwitterLink'
                inputLabel={<>Twitter Link</>}
                step='any'
                register={register('adminTwitterLink', {
                  required: 'Please enter twitter link.',
                  pattern: {
                    value: validationRules.httpLinkRegex,
                    message:
                      'Please enter valid link as: https://twitter.com/migraPay.'
                  }
                })}
              />
              <ErrorMessage message={errors?.adminTwitterLink?.message} />
            </div>

            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='text'
                name='adminYouTubeLink'
                id='adminYouTubeLink'
                inputLabel={<>YouTube Link</>}
                step='any'
                register={register('adminYouTubeLink', {
                  required: 'Please enter youtube link.',
                  pattern: {
                    value: validationRules.httpLinkRegex,
                    message:
                      'Please enter valid link as: https://www.youtube.com/@migraPay'
                  }
                })}
              />
              <ErrorMessage message={errors?.adminYouTubeLink?.message} />
            </div>

            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='text'
                name='adminFacebookLink'
                id='adminFacebookLink'
                inputLabel={<>Facebook Link</>}
                step='any'
                register={register('adminFacebookLink', {
                  required: 'Please enter facebook link.',
                  pattern: {
                    value: validationRules.httpLinkRegex,
                    message:
                      'Please enter valid link as: https://www.facebook.com/migraPay'
                  }
                })}
              />
              <ErrorMessage message={errors?.adminFacebookLink?.message} />
            </div>

            <div className='mb-4  w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='text'
                name='adminInstagramLink'
                id='adminInstagramLink'
                inputLabel={<>Instagram Link</>}
                step='any'
                register={register('adminInstagramLink', {
                  required: 'Please enter instagram link.',
                  pattern: {
                    value: validationRules.httpLinkRegex,
                    message:
                      'Please enter valid link as: https://www.instagram.com/migraPay'
                  }
                })}
              />
              <ErrorMessage message={errors?.adminInstagramLink?.message} />
            </div>
          </main>
        </div>
      </div>

      {/* <div className='border xl:w-full round'>
        <header className='border-b  py-2 px-4 bg-gray-100 rounded-t-md '>
          <div className='font-semibold'>General setting</div>
        </header>
        <div className='bg-white py-6 px-4  rounded-b-md'>
          <main className='justify-center flex flex-wrap grid  lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-4'>
            <div className='relative z-0 mb-6 w-full group'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='text'
                inputLabel={<>Country</>}
                name='country'
                register={register('country', {})}
                placeholder=''
                disabled
              />
            </div>
            <div className='w-full'>
              <OInputField
                wrapperClassName='relative z-0  w-full group'
                type='text'
                inputLabel={<>Currency</>}
                name='currency'
                register={register('currency', {})}
                placeholder=''
                disabled
              />
            </div>
          </main>
        </div>
      </div> */}
      {(manager?.edit || user?.role === 'admin') && (
        <div className='text-center mt-4 dark:mt-10 dark:mb-10'>
          <OButton
            title={t('O_UPDATE')}
            disabled={!isDirty}
            label={<>{t('O_UPDATE')}</>}
            type='submit'
            onClick={handleSubmit(handleSubmitForm)}
            loading={settingChangeLoading}
          />
        </div>
      )}

      {viewShowModal ? (
        <Credential setViewShowModal={setViewShowModal} email={user?.email} />
      ) : null}
    </section>
  )
}

export default Settings
