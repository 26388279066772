import React, { useContext } from 'react'
import { apiPut } from '../../utils/apiFetch'
import apiPath from '../../utils/pathObj'
import dayjs from 'dayjs'
import useToastContext from 'hooks/useToastContext'
import { AiFillEdit } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { BsArrowUpShort } from 'react-icons/bs'
import AuthContext from 'context/AuthContext'
import { isEmpty } from 'lodash'
import helpers from '../../utils/helpers'
import { useNavigate } from 'react-router'

const SubTable = ({ subAdmin, allSubAdmin, page, sort, setSort }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const notification = useToastContext()

  const handelStatusChange = async item => {
    try {
      const payload = {
        status: item?.status === 'inactive' ? 'active' : 'inactive'
      }
      const path = `${apiPath.statusSub}/${item?._id}`
      const result = await apiPut(path, payload)
      if (result?.status === 200) {
        notification.success(result.data.message)
        allSubAdmin({ statusChange: 1 })
      }
    } catch (error) {
      console.error('error in get all sub admin list==>>>>', error.message)
    }
  }

  return (
    <div className='p-3'>
      <div className='overflow-x-auto rounded-lg border relative'>
        <table className='dark:text-gray-400 w-full text-xs text-left text-[#A5A5A5]'>
          <thead className='text-xs text-gray-900 border border-[#E1E6EE] bg-[#E1E6EE] dark:bg-gray-700 dark:text-gray-400'>
            <tr>
              <th scope='col' className='py-3 px-6'>
                #
              </th>
              <th className='py-3 px-6 text-left' scope='col'>
                {t('SUB_ADMIN_ID')}
              </th>
              <th scope='col' className='py-3 text-left px-6'>
                {t('ID')}
              </th>
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_FIRST_NAME')}
              </th>
              <th scope='col' className='py-3 text-left px-6 '>
                {t('O_LAST_NAME')}
              </th>
              <th
                className='cursor-pointer py-3 px-6 '
                scope='col'
                onClick={() => {
                  if (sort.sortType === 'asc' && sort.sortKey === 'email') {
                    setSort({
                      sortKey: 'email',
                      sortType: 'desc'
                    })
                  } else {
                    setSort({
                      sortType: 'asc',
                      sortKey: 'email'
                    })
                  }
                }}
              >
                <div className='flex'>
                  <span>{t('O_EMAIL_ID')} </span>
                  <span>
                    {sort.sortKey === 'email' && sort.sortType === 'asc' && (
                      <BsArrowUpShort className='w-4 h-4' />
                    )}
                    {sort.sortKey === 'email' && sort.sortType === 'desc' && (
                      <BsArrowUpShort className='w-4 h-4 rotate-180' />
                    )}
                  </span>
                </div>
              </th>
              {/* <th scope='col' className='py-3 px-6 text-left'>
                {t('CONTACT_NUMBER')}
              </th> */}

              <th
                scope='col'
                className='py-3 px-6 cursor-pointer'
                onClick={() => {
                  if (sort.sortKey === 'createdAt' && sort.sortType === 'asc') {
                    setSort({
                      sortKey: 'createdAt',
                      sortType: 'desc'
                    })
                  } else {
                    setSort({
                      sortKey: 'createdAt',
                      sortType: 'asc'
                    })
                  }
                }}
              >
                <div className='justify-start flex '>
                  <span>{t('CREATED_DATE')} </span>
                  <span>
                    {sort.sortKey === 'createdAt' &&
                      sort.sortType === 'asc' && (
                        <BsArrowUpShort className='w-4 h-4' />
                      )}
                    {sort.sortKey === 'createdAt' &&
                      sort.sortType === 'desc' && (
                        <BsArrowUpShort className='rotate-180 w-4 h-4' />
                      )}
                  </span>
                </div>
              </th>
              {(user?.permission?.[6]?.add ||
                user?.permission?.length === 0) && (
                <th scope='col' className='text-left py-3 px-6 '>
                  {t('O_STATUS')}
                </th>
              )}
              <th scope='col' className='py-3 px-6 text-left'>
                {t('O_ACTION')}
              </th>
            </tr>
          </thead>
          <tbody>
            {subAdmin &&
              subAdmin?.map((item, i) => (
                <tr
                  key={i}
                  className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'
                >
                  <th
                    scope='row'
                    className='py-4 px-6 border-r font-medium text-gray-900  dark:text-white'
                  >
                    {i + 1 + 10 * (page - 1)}
                  </th>
                  <td className='py-4 px-6 text-gray-900 border-r'>
                    {item?._id}
                  </td>
                  <td className='py-4 text-gray-900 px-6 border-r'>
                    {item?.uniqueId}
                  </td>
                  <td className='text-gray-900 py-4 px-6 border-r'>
                    {item?.firstName}
                  </td>
                  <td className='py-4 px-6 border-r text-gray-900'>
                    {item?.lastName}
                  </td>
                  <td className='py-4 px-6 text-gray-900 border-r'>
                    {item?.email}
                  </td>
                  {/* <td className='py-4 px-6 border-r'>{item?.mobile} </td> */}
                  <td className='py-4 px-6 text-gray-900 border-r'>
                    {dayjs(item?.createdAt).format('DD/MM/YYYY hh:mm A')}
                  </td>
                  {(user?.permission?.[6]?.add ||
                    user?.permission?.length === 0) && (
                    <td className='py-4 px-6 border-r text-center'>
                      <label
                        className='inline-flex relative items-center cursor-pointer'
                        title={`${
                          item?.status === 'active' ? 'Active' : 'Inactive'
                        }`}
                      >
                        <input
                          type='checkbox'
                          className='sr-only peer'
                          checked={item?.status === 'active'}
                          onChange={e =>
                            helpers.alertFunction(
                              `Are you sure you want to ${
                                e.target.checked ? 'active' : 'inactive'
                              } "${item.firstName}" sub admin?`,
                              item,
                              handelStatusChange
                            )
                          }
                        />
                        <div className="w-10 h-5 bg-gray-200 rounded-full peer peer-focus:ring-0 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-LightBlue" />
                      </label>
                    </td>
                  )}
                  <td className='py-4 px-6 border-l'>
                    <div className=''>
                      <ul className='flex justify-center'>
                        {(user?.permission?.[5]?.add ||
                          user.permission?.length === 0) && (
                          <li
                            onClick={() => {
                              navigate('/sub-admin-manager/edit', {
                                state: item
                              })
                            }}
                            className='px-2 py-2 hover:bg-white hover:text-LightBlue dark:hover:bg-transparent'
                          >
                            <a title={t('EDIT_SUB_ADMIN')}>
                              {' '}
                              <AiFillEdit className='cursor-pointer w-5 h-5 text-slate-600' />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            {isEmpty(subAdmin) ? (
              <tr className='bg-white border-b w-full text-center dark:bg-gray-800 dark:border-gray-700'>
                <td className='py-4 px-6' colSpan={12}>
                  {t('O_NO_RECORD_FOUND')}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SubTable
